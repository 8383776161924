// MarketAlerts.jsx

import React, { useState, useEffect } from "react";
import { Modal, Button, message } from "antd";
import AlertTable from "./AlertTable";
import AlertEditor from "./AlertEditor";
import { fetchAlerts, deleteAlert, updateAlert, fetchClauses, createAlert } from "../functions/alertClauseService";
import useFetchDashboardStructure from "../functions/FetchDashboardStructure";

const MarketAlerts = ({ isVisible, onClose }) => {
  const [alerts, setAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(true);
  const [alertsError, setAlertsError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [clauses, setClauses] = useState([]);
  const { dashboardStructure: fetchedStructure } = useFetchDashboardStructure();
  const [dashboardStructure, setDashboardStructure] = useState(null);

  useEffect(() => {
    if (!dashboardStructure && fetchedStructure) {
      setDashboardStructure(fetchedStructure);
    }
  }, [fetchedStructure, dashboardStructure]);

  // console.log("dashboardStructure in MarketAlerts ", dashboardStructure);

useEffect(() => {
  const loadAlerts = async () => {
    setAlertsLoading(true);  // Reset loading state when fetching alerts starts
    try {
      const data = await fetchAlerts();
      setAlerts(data);
      setAlertsError(null);  // Clear any previous error
    } catch (error) {
      setAlertsError("Error fetching alerts");
    } finally {
      setAlertsLoading(false);  // Ensure loading is set to false after fetching
    }
  };

  if (isVisible) {  // Only fetch alerts if modal is visible
    loadAlerts();
  }
}, [isVisible]);  // Depend on `isVisible` to re-fetch alerts on modal open


  const handleEditAlert = async (alertId) => {
    const alertToEdit = alerts.find((alert) => alert.ID === alertId);
    setSelectedAlert(alertToEdit);
    setIsEditing(true);

    try {
      const clauseData = await fetchClauses();
      setClauses(clauseData);
    } catch (error) {
      console.error("Error fetching clauses:", error);
    }
  };

  const handleDeleteAlert = (alertId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this alert?",
      onOk: async () => {
        try {
          await deleteAlert(alertId);
          setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.ID !== alertId));
        } catch (error) {
          console.error("Error deleting alert:", error);
        }
      },
    });
  };

  const handleSaveAlert = async (alertData, action) => {
    try {
      if (action === "update") {
        // Update existing alert
        await updateAlert(alertData);
        await fetchAlerts().then(data => setAlerts(data));
   
      } else if (action === "create") {
        // Create new alert
        await createAlert(alertData);
        await fetchAlerts().then(data => setAlerts(data));

      }
  
      setIsEditing(false);
      setSelectedAlert(null); // Clear selected alert after saving
    } catch (error) {
      console.error("Error saving alert:", error);
    }
  };
  

  const handleAddAlert = async () => {
    setSelectedAlert(null); // Clear selected alert to start with a new one
    setIsEditing(true); // Open AlertEditor for new alert creation
    try {
      const clauseData = await fetchClauses();
      setClauses(clauseData);
    } catch (error) {
      console.error("Error fetching clauses:", error);
    }
  };

  return (
    <Modal
      title="Creating Market Alerts"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onClose}>
          OK
        </Button>,
        <Button key="add" type="primary" onClick={handleAddAlert}>
          Add Alert
        </Button>,
      ]}
      width={1200}
    >
      <AlertTable
        alerts={alerts}
        alertsLoading={alertsLoading}
        alertsError={alertsError}
        tableTitle="Market Alerts"
        onEditAlert={handleEditAlert}
        onDeleteAlert={handleDeleteAlert}
      />

      <AlertEditor
        visible={isEditing}
        onClose={() => setIsEditing(false)}
        alertData={selectedAlert}
        onSave={handleSaveAlert}
        clauses={clauses}
        dashboardStructure={dashboardStructure}
      />
    </Modal>
  );
};

export default MarketAlerts;
