import React, { useState } from 'react';
import { Table, Tooltip, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import api from '../functions/api';

// Redux action to update the 'seenby' status
const updateSeenByStatus = (id, seenby) => {
  return {
    type: 'UPDATE_SEENBY_STATUS',
    payload: { id, seenby },
  };
};

const Market_Alerts = () => {
    const dispatch = useDispatch();
    const marketAlerts = useSelector(state => state?.portfolio?.data?.market_alerts);
    const userId = getUserId(); // Retrieve the userId to use as seenby value

    console.log("Market alerts in Market_Alerts", marketAlerts);

    // Sort alerts by seen status first, then by alert type alphabetically (if needed)
    const sortedHarvestAlerts = [...marketAlerts].sort((a, b) => a.seen - b.seen);

    const formatAlertText = (text) => {
      if (!text) return '';
      const becauseIndex = text.indexOf('because:');
      if (becauseIndex === -1) return text;
  
      const beforeBecause = text.substring(0, becauseIndex + 8);
      const afterBecause = text.substring(becauseIndex + 8).replace(/ - /g, '\n- ');
      
      return `${beforeBecause}${afterBecause}`;
    };

    // Function to determine if the current user has marked the alert as seen
    const isCheckedByUser = (seenby) => {
      if (!seenby) return false;
      const seenByUsers = seenby.split(',').map(id => id.trim()); // Split and trim values
      return seenByUsers.includes(userId.toString());
    };

    const handleCheckboxChange = async (id, currentSeenby) => {
      try {
        const seenByUsers = currentSeenby ? currentSeenby.split(',').map(id => id.trim()) : [];
        const isAlreadyChecked = seenByUsers.includes(userId.toString());

        // Toggle the user in the seenby list
        const updatedSeenByUsers = isAlreadyChecked 
          ? seenByUsers.filter(user => user !== userId.toString()) // Remove user
          : [...seenByUsers, userId.toString()]; // Add user

        const newSeenbyValue = updatedSeenByUsers.join(', '); // Join as a comma-separated string

        // Send the updated seenby value to the backend
        await api.post('/pf_alert_check_update', { id, seenby: newSeenbyValue });
        dispatch(updateSeenByStatus(id, newSeenbyValue));

        // Update the local state to reflect the change
        const updatedAlerts = marketAlerts.map(alert =>
          alert.ID === id ? { ...alert, seenby: newSeenbyValue } : alert
        );
        // Update Redux or local state as needed
      } catch (error) {
        console.error('Failed to update seenby status', error);
      }
    };

      
    const columns = [
      {
        title: 'Alert Type',
        dataIndex: 'alert_type',
        key: 'alert_type',
      },
      {
        title: 'Alert Subtype',
        dataIndex: 'alert_subtype',
        key: 'alert_subtype',
      },
      {
        title: 'Checked?',
        dataIndex: 'seenby',
        key: 'seenby',
        render: (text, record) => (
          <Checkbox 
            checked={isCheckedByUser(record.seenby)} 
            onChange={() => handleCheckboxChange(record.ID, record.seenby)}
          />
        ),
      },
      {
        title: 'Alert Text',
        dataIndex: 'pf_alert_text',
        key: 'pf_alert_text',
        render: (text) => (
          <Tooltip title={text}>
            <span style={{ whiteSpace: 'pre-wrap' }}>{formatAlertText(text)}</span>
          </Tooltip>
        ),
      },
    ];

    return (
      <>
        <Table
          dataSource={sortedHarvestAlerts}
          columns={columns}
          rowKey="ID"
          pagination={{ pageSize: 5 }}
        />
       
      </>
    );
  };

  export default Market_Alerts;
