//ClauseManagement.jsx
import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Space } from 'antd';
import { fetchClauses, deleteClause } from "../functions/alertClauseService";
import useFetchDashboardStructure from "../functions/FetchDashboardStructure";
import ClauseEditor from './ClauseEditor'; // Import the new ClauseEditor component

const ClauseManagement = ({ isVisible, onClose }) => {
  const [clauses, setClauses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingClause, setEditingClause] = useState(null); // Track the clause being edited
  const { dashboardStructure: fetchedStructure } = useFetchDashboardStructure();
  const [dashboardStructure, setDashboardStructure] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (isVisible) {
      // Set all rows to expanded by default when modal becomes visible
      setExpandedRowKeys(clauses.map((clause) => clause.ID));
    }
  }, [isVisible, clauses]);

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys((prevKeys) =>
      expanded ? [...prevKeys, record.ID] : prevKeys.filter((key) => key !== record.ID)
    );
  };

  const toggleExpandCollapseAll = () => {
    if (expandedRowKeys.length === clauses.length) {
      setExpandedRowKeys([]); // Collapse all rows
    } else {
      setExpandedRowKeys(clauses.map((clause) => clause.ID)); // Expand all rows
    }
  };

  useEffect(() => {
    if (!dashboardStructure && fetchedStructure) {
      setDashboardStructure(fetchedStructure);
    }
  }, [fetchedStructure, dashboardStructure]);

  useEffect(() => {
    const loadClauses = async () => {
      setLoading(true);
      try {
        const fetchedClauses = await fetchClauses();
        console.log("fetched clauses in CLauseManagement ", fetchedClauses)
        
        setClauses(fetchedClauses);
      } catch (error) {
        console.error('Error fetching clauses:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isVisible) {
      loadClauses();
    }
  }, [isVisible]);

  const refreshClauses = async () => {
    const updatedClauses = await fetchClauses();
    
    setClauses(updatedClauses);
  };

  const columns = [
    { title: 'ID', dataIndex: 'ID', key: 'ID' },
    { title: 'Clause Field', dataIndex: 'clause_field', key: 'clause_field', render: (text, record) => `${text} (${record.clause_field_sourcetable})` },
    // Add Clause Field Sourcetable column
    { title: 'Clause Field Sourcetable', dataIndex: 'clause_field_sourcetable', key: 'clause_field_sourcetable' },



    { title: 'Clause Field Target', dataIndex: 'clause_field_target', key: 'clause_field_target' },
    { title: 'Clause Field Target Value', dataIndex: 'clause_field_target_value', key: 'clause_field_target_value' },
    { title: 'Operator', dataIndex: 'clause_operator', key: 'clause_operator' },
    { title: 'Threshold', dataIndex: 'threshold', key: 'threshold' },
    { title: 'Threshold Field', dataIndex: 'threshold_field', key: 'threshold_field', render: (text, record) => `${text} (${record.threshold_sourcetable})` },
    // Add Threshold Sourcetable column
    { title: 'Threshold Sourcetable', dataIndex: 'threshold_sourcetable', key: 'threshold_sourcetable' },
    // Add Threshold Field Target column
    { title: 'Threshold Field Target', dataIndex: 'threshold_field_target', key: 'threshold_field_target' },

    // Add Threshold Field Target Value column
    { title: 'Threshold Field Target Value', dataIndex: 'threshold_field_target_value', key: 'threshold_field_target_value' },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => setEditingClause(record)}>Edit</Button>
          {!record.alerts || record.alerts.length === 0 ? (
            <Button danger onClick={() => handleDeleteClause(record.ID)}>Delete</Button>
          ) : (
            <span>
              Part of alerts {record.alerts.join(', ')}
            </span>
          )}
        </Space>
      ),
    },
    
  ];

  const handleDeleteClause = async (clauseId) => {
    setLoading(true);
    try {
      await deleteClause(clauseId);
      setClauses(clauses.filter(clause => clause.ID !== clauseId));
    } catch (error) {
      console.error('Error deleting clause:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* // Add expandedRowRender to display clause_txt in an expanded row */}
      <Modal
      title="Manage Clauses"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
      width={2000}
    >
      <Space style={{ marginBottom: 16 }}>
        <Button onClick={toggleExpandCollapseAll}>
          {expandedRowKeys.length === clauses.length ? 'Close All Clause Texts' : 'Open All Clause Texts'}
        </Button>
        <Button type="primary" onClick={() => setEditingClause({})}> {/* Open ClauseEditor with empty form */}
          Create New Clause
        </Button>
      </Space>
      
      <Table
        dataSource={clauses}
        size="small"
        columns={columns}
        rowKey="ID"
        loading={loading}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ padding: '8px 24px', fontStyle: 'italic', color: 'blue' }}> {/* Color clause_txt blue */}
              {record.clause_txt}
            </div>
          ),
          expandedRowKeys: expandedRowKeys, // Control expanded rows
          onExpand: handleExpand, // Handle row expansion/collapse
        }}
      />
    </Modal>


      {editingClause && (
        <ClauseEditor
          visible={!!editingClause}
          onClose={() => setEditingClause(null)}
          clause={editingClause}
          dashboardStructure={dashboardStructure}
          refreshClauses={refreshClauses}
        />
      )}
    </>
  );
};

export default ClauseManagement;
