// AlertTable.jsx

import React from "react";
import { Table, Spin, Alert, Collapse, Button } from "antd";

const { Panel } = Collapse;

const AlertTable = ({
  alerts,
  alertsLoading,
  alertsError,
  tableTitle,
  onEditAlert,
  onDeleteAlert,
}) => {
  const alertColumns = [
    { title: "ID", dataIndex: "ID", key: "ID" },
    { title: "Scope", dataIndex: "scope", key: "scope" },
    { title: "Alert Type", dataIndex: "alert_type", key: "alert_type" },
    {
      title: "Advice Text",
      dataIndex: "advice_txt",
      key: "advice_txt",
      render: (text) => {
        // Regular expression to find text within <> brackets
        const parts = text.split(/(<[^>]+>)/g).map((part, index) =>
          part.startsWith("<") && part.endsWith(">")
            ? <span key={index} style={{ fontStyle: "italic" }}>{part}</span>
            : <span key={index} style={{ fontWeight: "bold" }}>{part}</span>
        );
        return <div>{parts}</div>;
      },
    },
    { title: "Clauses", dataIndex: "clauses", key: "clauses" },
    { title: "Subtype", dataIndex: "alert_subtype", key: "alert_subtype" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => onEditAlert(record.ID)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => onDeleteAlert(record.ID)}>
            Delete
          </Button>
        </>
      ),
    },
  ];
  

  return (
    <Collapse defaultActiveKey={["1"]} size="small">
      <Panel header={tableTitle} key="1">
        {alertsLoading ? (
          <Spin tip="Loading alerts..." />
        ) : alertsError ? (
          <Alert message={alertsError} type="error" />
        ) : (
          <Table
            columns={alertColumns}
            dataSource={alerts}
            pagination={false}
            rowKey="ID"
            bordered
          />
        )}
      </Panel>
    </Collapse>
  );
};

export default AlertTable;
