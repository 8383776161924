///Widget_controller
import React, { useState, useEffect } from 'react';
import { Modal, Radio, message, Button, Input } from 'antd';
import api from '../functions/api';
import { useDispatch } from 'react-redux';
import { updateWidgetContent } from '../redux/actions';
import SearchBox2 from '../functions/Search_Generic.js';

const WidgetController = ({ userId, widgetNumber, onContentSelected, existingContentId, onUpdateWidgetContent }) => {
  
  const dispatch = useDispatch();
  const [contentOptions, setContentOptions] = useState([]);
  const [selectedContentId, setSelectedContentId] = useState(existingContentId);
  const [tickerValue, setTickerValue] = useState('');
  const [isTickerSelected, setIsTickerSelected] = useState(false);


  const onTickerNewsSelected = async (selectedTicker) => {
    setTickerValue(selectedTicker);
    const payload = {
      userid: userId,
      widget_number: widgetNumber,
      content_type: 'table',
      content_subtype: 'tickernews',
      ticker: selectedTicker
    };

    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        setIsTickerSelected(true);
        // console.log("widgetcontentid from response ",response.data.widgetcontentid)
        setSelectedContentId(response.data.widgetcontentid);
        // console.log("selectedContentId", selectedContentId)
        // Handle successful response
        message.success('Tickernews content set successfully!');
      } else {
        // Handle any other HTTP status
        message.error('An error occurred while setting the Tickernews content.');
      }
    } catch (error) {
      // Handle errors from the server or network issues
      console.error('Error posting widget content:', error);
      message.error('An error occurred while posting the widget content.');
    }
  };

  const onTickerSelected = async (selectedTicker) => {
    setTickerValue(selectedTicker);
    const payload = {
      userid: userId,
      widget_number: widgetNumber,
      content_type: 'graph',
      ticker: selectedTicker
    };

    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        setIsTickerSelected(true);
        // console.log("widgetcontentid from response ",response.data.widgetcontentid)
        setSelectedContentId(response.data.widgetcontentid);
        // console.log("selectedContentId", selectedContentId)
        // Handle successful response
        message.success('Graph content set successfully!');
      } else {
        // Handle any other HTTP status
        message.error('An error occurred while setting the graph content.');
      }
    } catch (error) {
      // Handle errors from the server or network issues
      console.error('Error posting widget content:', error);
      message.error('An error occurred while posting the widget content.');
    }
  };

  useEffect(() => {
    // console.log("selectedContentId updated to", selectedContentId);
  }, [selectedContentId]); // This effect runs whenever selectedContentId changes.
  

  const handleClear = async () => {
    // console.log("CLEARING");
    const payload = { userid: userId, widget_number: widgetNumber, widgetcontentid: null };
  
    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      if (response.status === 200) {
        dispatch(updateWidgetContent(userId, widgetNumber, null)); // Dispatch with null to represent no content
        onContentSelected(null); // Call onContentSelected with null
        setContentOptions([]); // This will trigger a re-render
        message.success('Widget cleared successfully!', 1.5);
      } else {
        console.error('Backend returned an error:', response);
      }
    } catch (error) {
      console.error('Error clearing widget content:', error);
    }
  };
  

  //to select options to fill a widget
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post('/widget_content_management', JSON.stringify({}), {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        // console.log("Full RESPONSE: ", response);
        
        // Filter out options where type is 'graph'
        const nonGraphOptions = response.data.filter(option => option.type !== 'graph');
        
        const formattedData = nonGraphOptions.map(option => ({
          ...option,
          added_by: option.added_by === null ? 'null' : option.added_by.toString()
        }));
        setContentOptions(formattedData);
      } catch (error) {
        console.error('Error fetching widget content:', error);
      }
    };
  
    fetchData();
  }, [userId, widgetNumber]);
  

  const stringUserId = userId?.toString();
  const selfAddedOptions = contentOptions.filter(option => option.added_by === stringUserId);
  const otherOptions = contentOptions.filter(option => option.added_by !== stringUserId && option.type !== 'widget_personal');

  const relatedToYourPortfolioOptions = contentOptions.filter(option => option.type === 'widget_personal');


  const handleSelection = (e) => {
    setSelectedContentId(e.target.value);
  };

  const handleOk = async () => {
    
    let payload;
    if (selectedContentId === 'clear') {
      // console.log("CLEARING")
      payload = { userid: userId, widget_number: widgetNumber, widgetcontentid: null };
    } else {
      payload = { userid: userId, widget_number: widgetNumber, widgetcontentid: selectedContentId };
    }

    try {
      const response = await api.post('/widget_content_management', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        dispatch(updateWidgetContent(userId, widgetNumber, selectedContentId));
        onContentSelected(selectedContentId);
        setContentOptions([]); // This will close the modal
        message.success('Widget updated successfully!', 1.5);
      } else {
        console.error('Backend returned an error:', response);
      }
    } catch (error) {
      console.error('Error updating widget content:', error);
    }

  };

  return (
    <Modal
  title="Select Content for Widget"
  visible={true}
  onOk={handleOk}
  onCancel={() => onContentSelected(existingContentId)}
  width="100%"
  footer={[
    <Button key="back" onClick={() => onContentSelected(existingContentId)}>
      Cancel
    </Button>,
    <Button key="clear" type="default" onClick={handleClear}>
      Clear Widget
    </Button>,
    <Button key="submit" type="primary" onClick={handleOk}>
      Save Changes
    </Button>,
  ]}
>

<div>
  {relatedToYourPortfolioOptions.length > 0 && (
    <>
      <div><b>Related to your own portfolio:</b></div>
      <Radio.Group onChange={handleSelection} value={selectedContentId}>
        {relatedToYourPortfolioOptions.map((option) => (
          <Radio key={option.ID} value={option.ID}>{option.description}</Radio>
        ))}
      </Radio.Group>
    </>
  )}
</div>


  <div><b>Created by yourself:</b></div>
  <Radio.Group onChange={handleSelection} value={selectedContentId}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ flex: 1 }}>
        {selfAddedOptions.length > 0 && selfAddedOptions.map((option) => (
          <Radio key={option.ID} value={option.ID}>
            {option.description}
          </Radio>
        ))}
      </div>
    </div>
  </Radio.Group>
  {otherOptions.length > 0 && (
    <>
      <div><b>Other Options:</b></div>
      <Radio.Group onChange={handleSelection} value={selectedContentId}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            {otherOptions.map((option) => (
              <Radio key={option.ID} value={option.ID}>
                {option.description}
              </Radio>
            ))}
          </div>
        </div>
      </Radio.Group>
    </>
  )}


 <div><b>Choose graph:</b></div>
 <SearchBox2 onSelectTicker={onTickerSelected} />

 <div><b>Choose latest companynews:</b></div>
 <SearchBox2 onSelectTicker={onTickerNewsSelected} />

</Modal>

  );
};

export default WidgetController;








