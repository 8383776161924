
/// PortfolioView.jsx
import React, { useState, useEffect } from 'react';
import { Button, message, Spin, Space, Tooltip, Badge } from 'antd';

import PortfolioModal from './PortfolioModal';
import api from '../functions/api';
import Broker from './BrokerModal';
import getUserId from '../functions/getUserId';
import TickerSearch from './TickerSearch';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
// import { getPortfolioData } from '../redux/portfolioReducer';


const PortfolioView = ({ userId, disabled }) => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  // Access the portfolio slice
  const store_portfolioData = useSelector(state => state.portfolio.data);
  const store_portfolioStatus = useSelector(state => state.portfolio.status);

  
  const [portvisible, setPortVisible] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [fieldMetadata, setFieldMetadata] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
 
  const [showBrokerModal, setShowBrokerModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [updatedCategoryRecords, setUpdatedCategoryRecords] = useState([]);

  
  const [loading, setLoading] = useState(false);
  const [favorites, setFavorites] = useState({});

  const hasUnseenAlerts = useSelector(state =>
    state.portfolio.data.pf_alert?.some(alert => alert.seen === 0)
  );

  const userIdd = userId || getUserId(); // Use provided userId or retrieve it directly
console.log("Current userId:", userIdd); // Debug userId

const hasUnseenmarketAlerts = useSelector(state =>
  state.portfolio.data.market_alerts?.some(alert => {
    // Skip check if userIdd is null or undefined
    if (!userIdd) {
      console.log("userId is not defined, skipping alert check.");
      return false;
    }

    // Log each alert and its seenby value
    // console.log("Checking alert ID:", alert.ID, "seenby:", alert.seenby);

    // Split, trim, and filter out empty strings from seenby
    const seenbyUsers = alert.seenby 
      ? alert.seenby.split(',').map(id => id.trim()).filter(id => id) 
      : [];
    
    // console.log("Parsed seenbyUsers for alert ID", alert.ID, ":", seenbyUsers);

    // Check if the userId is absent
    const isUnseen = !seenbyUsers.includes(userIdd.toString());
    // console.log(`Alert ID ${alert.ID} isUnseen for user ${userIdd}:`, isUnseen);

    return isUnseen;
  })
);

  
  // Final check
  console.log("hasUnseenmarketAlerts result IN PVIEW:", hasUnseenmarketAlerts);

  

    useEffect(() => {
      const initFetch = async () => {
        // console.log("initFetch")
        const userId = await updateUserCheck();
        // await fetchPortfolioData(userId);
      };
      initFetch();
    }, [userId]);

    const handleTickerSelect = (ticker, record) => {
      const newSelectedTickers = { ...selectedTickers, [record.id]: ticker };
      setSelectedTickers(newSelectedTickers);
    
      setPortfolioData((prev) => {
        return prev.map((item) => {
          if (item.id === record.id) {
            const updatedItem = { 
              ...item, 
              isTickerSelected: true, 
              Ticker: newSelectedTickers[record.id] 
            };

            console.log("updatedItem",updatedItem);
            // Retrieve the user ID
            const userId = getUserId();
    
            // API Call to /api/portfolio_view_update
            api.post("/portfolio_view_update", {
              UserID: userId,
              Ticker: updatedItem.Ticker,
              Company: updatedItem.Company,
              Position: updatedItem.Position,
              Close: updatedItem.Close,
              Value_eur: updatedItem.Value_eur
            }, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              }
            })
            .then(response => console.log(response.data))
            .catch((error) => {
              console.error('Error:', error);
            });
            
    
            return updatedItem;
          }
          return item;
        });
      });
    };
    

  const [selectedTickers, setSelectedTickers] = useState({});

  const updateUserCheck = async () => {
    try {
      const response = await api.get('/user_check');
      // console.log("User checked: ", response.data);
      // setUserId(response.data.ID);  // Save the user ID in the state
      return response.data.ID;  // Return the user ID
    } catch (error) {
      console.error("Error checking user:", error);
    }
  };
  
  const checkDeGiroCredentials = async (userId) => {
    try {
      // console.log("now checking if DeGiro credentials are available for UserID", userId);
      
      const response = await api.get(`/check_DeGiro_credentials/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      return true; // Return true if the credentials check passes
    } catch (error) {
      console.error("Error checking DeGiro credentials:", error);
    }
  };

const handleButtonClick = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    setLoading(true);
    await updateUserCheck().then(async (userId) => {
      const credentialsValid = await checkDeGiroCredentials(userId);
      if (credentialsValid) {
        setLoading(false);
        setPortVisible(true);
        // await fetchPortfolioData(userId);  // directly call fetchPortfolioData here
      } else {
        return;
      }
    });
  } else {
    message.warning('You need to log in to start using the Portfolio functionality');
  }
};

  
  return (
    <>
      <Space align="center">
      <Tooltip title="Download your portfolio. Create custom overviews and define your custom portfolio alerts." mouseEnterDelay={1.5} placement="bottom">
      
      <Badge dot={hasUnseenAlerts || hasUnseenmarketAlerts} color="red">
        <Button type="primary" onClick={handleButtonClick} disabled={loading || disabled}>
          Portfolio
        </Button>
      </Badge>

      
        </Tooltip>
        {loading && <Spin />} {/* This line adds the loading spinner */}
        <p>{loading ? 'Updating and fetching portfolio data, please wait...' : ''}</p>
      </Space>
  
      <PortfolioModal
        
        portvisible={portvisible}
        onClose={() => setPortVisible(false)}
        portfolioData={portfolioData}
        tableColumns={tableColumns}
        userId={userId}
        favorites={favorites}
        setFavorites={setFavorites}
        fieldMetadata={fieldMetadata}
        categoryOptions={categories}
        updatedCategoryRecords={updatedCategoryRecords}


        handleTickerSelect={handleTickerSelect}
        isTickerSelected={portfolioData.map(item => item.isTickerSelected)}
      />
      <Broker
        showBrokerModal={showBrokerModal}
        onClose={() => setShowBrokerModal(false)}
        userId={userId}
      
      />
    </>
  );

};

export default PortfolioView;

