// ///Account.jsx

import React, { useState, useEffect, useRef } from 'react';
import api from '../functions/api';
import { Dropdown, Menu, Button, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PreferencesModal from './PreferencesModal.jsx';
import User from './Users.jsx';
import useLocalStorage from '../functions/useLocalStorage';
import getUserId from '../functions/getUserId';
import CategoryPreferencesModal from './CategoryPreferencesModal.jsx';
import MaintenanceModal from './MaintenanceModal.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { logIn, logOut, clearUserData, clearPortfolioData,setWidgetPreferences } from '../redux/actions';
import store from '../redux/store';
import { getPortfolioData, getFavoritesData } from '../redux/reducers/portfolioReducer';
import { getDataParameters, setFilteredTickers,setFilteredTickersF, getPortfolioESGData, getFavoritesESGData } from '../redux/reducers/portfolioReducer';
import BrokerAccessModal from './BrokerAccessModal.jsx';
import { getStoreSize } from '../functions/storeSize';
import fetchWidgetprefs from '../functions/fetch_storeWidgetprefs';
import ESGChecker from './ESGChecker.jsx';
import MarketAlerts from './MarketAlerts.jsx';
import ClauseManagement from './ClauseManagement.jsx';

const Account = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [token, setToken] = useLocalStorage('token', null);
  const [isMaintenanceModalVisible, setIsMaintenanceModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const dataParameters = useSelector((state) => state.portfolio.dataParameters);
  const [isDatabaseModalVisible, setIsDatabaseModalVisible] = useState(false); // New state for the modal visibility


  const dispatch = useDispatch();

  const { userName } = useSelector(state => state.auth);

  const isDebugMode = useSelector(state => state.debugMode?.isDebugMode);
  // Log the entire state only when debugMode.isDebugMode is true
  // Log the entire state only when isDebugMode is true
  useEffect(() => {
    if (isDebugMode) {
      // Use store.getState() to access the full state when debug mode is on
      const allState = store.getState();  // Assuming 'store' is imported or accessible in this file
      console.log("All State: ", allState);
      console.log("isDebugMode: ", isDebugMode);
    }
  }, [isDebugMode]);  // Effect runs only when isDebugMode changes


  // // const state = useSelector((state) => state);
  // const allState = useSelector(state => state);
  // // Only log allState when debugMode.isDebugMode is true
  // if (allState.debugMode?.isDebugMode) {
  //   console.log("All State: ", allState);
  // }


  const prevFilteredTickersRef = useRef();
  const prevFilteredTickersFRef = useRef();

  const stored_portfolioData = useSelector((state) => state.portfolio.data);
  // console.log("stored_portfolioData: ", stored_portfolioData);
  const stored_favoritesData = useSelector((state) => state.favorites?.data);

  const allTickers = stored_portfolioData?.portfolio?.map(item => item.Ticker) || [];

  const allTickersF = stored_favoritesData?.data?.map(item => item.Ticker) || [];

  const filteredTickers = allTickers.filter(ticker => ticker !== null && ticker !== '');
  const filteredTickersF = allTickersF.filter(ticker => ticker !== null && ticker !== '');

  const [isESGCheckerVisible, setIsESGCheckerVisible] = useState(false);
const [isClauseModalVisible, setIsClauseModalVisible] = useState(false);

const handleClausesClick = () => {
  setIsClauseModalVisible(true);
};

const handleClauseModalClose = () => {
  setIsClauseModalVisible(false);
};

  
  const handleESGCheckerClick = () => {
    window.open('/esgcheck', '_blank');
  };
  
  

  useEffect(() => {
    const storeSize = getStoreSize();
    console.log("Redux Store Size in bytes:", storeSize);
  }, []);

  useEffect(() => {
    if (JSON.stringify(prevFilteredTickersRef.current) !== JSON.stringify(filteredTickers)) {
      // console.log("ACCOUNT: Dispatching filteredTickers: ", filteredTickers);
      dispatch(setFilteredTickers(filteredTickers));
    }
    prevFilteredTickersRef.current = filteredTickers;
  }, [filteredTickers, dispatch]);
  
  useEffect(() => {
    if (JSON.stringify(prevFilteredTickersFRef.current) !== JSON.stringify(filteredTickersF)) {
      // console.log("ACCOUNT: Dispatching filteredTickersF: ", filteredTickersF);
      dispatch(setFilteredTickersF(filteredTickersF));
    }
    prevFilteredTickersFRef.current = filteredTickersF;
  }, [filteredTickersF, dispatch]);

  // useEffect(() => {
  //   dispatch(getDataParameters());
  //   console.log("get DP dispatch")
  // }, []);
  
  useEffect(() => {
    // console.log("check on dataparameters")
    if (!dataParameters) {
      dispatch(getDataParameters());
      // console.log("get DP dispatch")
    }
  }, [dataParameters, dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
    // console.log("isLoggedIn: ", isLoggedIn);
    dispatch(getPortfolioData(token));
    dispatch(getFavoritesData(token));
    // dispatch(getDataParameters());

    }
  }, [isLoggedIn, token, dispatch]);
  // }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && stored_portfolioData) {
    // console.log("isLoggedIn: ", isLoggedIn);
    dispatch(getPortfolioESGData(filteredTickers));
    }
  }, [isLoggedIn, stored_portfolioData]);

  useEffect(() => {
    if (isLoggedIn && stored_favoritesData) {
      // console.log("filteredTickersF: ", filteredTickersF)
    // console.log("isLoggedIn: ", isLoggedIn);
    dispatch(getFavoritesESGData(filteredTickersF));
    }
  }, [isLoggedIn, stored_favoritesData]);

  useEffect(() => {
    if (isLoggedIn && userId && token) {
      // Fetch and set widget preferences
      const fetchAndUpdateWidgetPrefs = async () => {
        try {
          const widgetPrefs = await fetchWidgetprefs(userId, token);
          // console.log("widgetprefs fetched:", widgetPrefs)
          dispatch(setWidgetPreferences(widgetPrefs));
        } catch (error) {
          console.error('Error fetching widget preferences:', error);
        }
      };
      fetchAndUpdateWidgetPrefs();
    }
  }, [isLoggedIn, userId, token, dispatch]); 


  const [isPreferencesModalVisible, setIsPreferencesModalVisible] = useState(false);
  const [modalState, setModalState] = useState({
    isLoginRegisterModalVisible: false,
    defaultTab: "2"
  });

  const [isCategoryPreferencesModalVisible, setIsCategoryPreferencesModalVisible] = useState(false);
  const [categoryPreferences, setCategoryPreferences] = useState([]);
  const [formattedName, setFormattedName] = useState(null);
  const [isBrokeraccessmodalVisible, setIsBrokeraccessmodalVisible] = useState(false);
  const handleBrokeraccessModalClose = () => {
    setIsBrokeraccessmodalVisible(false);};
 
  useEffect(() => {
    const fetchUserDetails = async () => {
      const storedToken = localStorage.getItem('token');
      setToken(storedToken);
  
      if (!storedToken) {
        dispatch(logOut());
        return;
      }
  
      try {
        const response = await api.get('/user_check');
        const { ID, first_name, last_name } = response.data;
        // console.log ("response.data: ", response.data);
        const formattedName = `${first_name} ${last_name}`;
        setUserId(ID); 
        setFormattedName(formattedName);
        dispatch(logIn({ ID, userName: formattedName }));  // Dispatch logIn action to Redux
  
      } catch (error) {
        console.error('Error fetching user details:', error);
        localStorage.removeItem('token');
        dispatch(logOut());
      }
    };
  
    fetchUserDetails();
  }, [token]);

  const handleLogout = () => {
    dispatch(clearUserData()); // Dispatch logOut action to Redux
    dispatch(clearPortfolioData());
    dispatch({ type: 'CLEAR_USER_DATA' })
    dispatch({ type: 'CLEAR_PORTFOLIO_DATA' })
    dispatch({ type: 'CLEAR_FAVORITES_DATA' })
    localStorage.removeItem('token');
    setToken(null); // clear the token state
    // setUserLoggedIn(prev => !prev);  // Toggle to trigger re-render
  
    dispatch(logOut());

    console.log("clearUserData dispatched");
    console.log('Redux State:', store.getState());


    setModalState({ ...modalState, isLoginRegisterModalVisible: false });
  };

  const handleCategoryPreferencesClick = () => {
    setIsCategoryPreferencesModalVisible(true);
};


const handleBrokeraccessClick = () => {
  setIsBrokeraccessmodalVisible(true);
};

  const handlePreferencesClick = () => {
    setIsPreferencesModalVisible(true);
  };

  const handlePreferencesModalClose = () => {
    setIsPreferencesModalVisible(false);
  };

  const handleLoginClick = () => {
    setModalState({
      isLoginRegisterModalVisible: true,
      defaultTab: "2"
    });
  };

  const handleRegisterClick = () => {
    setModalState({
      isLoginRegisterModalVisible: true,
      defaultTab: "1"
    });
  };

  const handleMaintenanceClick = () => {
    setIsMaintenanceModalVisible(true); // Set the modal visibility to true
  };
  
  // Add handler to open the Database modal
  const handleDatabaseClick = () => {
    setIsDatabaseModalVisible(true);  // This will open the Database modal
  };

  const handleDatabaseModalClose = () => {
    setIsDatabaseModalVisible(false);  // This will close the Database modal
  };

  const menu = (
    <Menu>
      {isLoggedIn ? (
        <>
          <Menu.Item key="0.5" onClick={handleCategoryPreferencesClick}>
            Category Preferences
          </Menu.Item>
          <Menu.Item key="0" onClick={handlePreferencesClick}>
            Data Preferences
          </Menu.Item>
          <Menu.Item key="0.7" onClick={handleBrokeraccessClick}>
            Broker Access
          </Menu.Item>
          <Menu.Item key="1" onClick={handleLogout}>
            Logout
          </Menu.Item>
          {isLoggedIn && (userId === 1 || userId === 2) && (
            <Menu.Item key="0.1" onClick={handleMaintenanceClick}>
              Maintenance
            </Menu.Item>
          )}
          {isLoggedIn && userId === 2 && (  // Database option only for userId === 2
            <Menu.Item key="0.2" onClick={handleDatabaseClick}>
              Market Alerts
            </Menu.Item>
          )}

          {isLoggedIn && userId === 2 && (  // Database option only for userId === 2
            <Menu.Item key="0.25" onClick={handleClausesClick}>
              Clauses
            </Menu.Item>
          )}

          {isLoggedIn && (userId === 1 || userId === 2) && (
            <Menu.Item key="0.09" onClick={handleESGCheckerClick}>
              ESG Checker
            </Menu.Item>
          )}
        </>
      ) : (
        <>
          <Menu.Item key="0" onClick={handleLoginClick}>
            Login
          </Menu.Item>
          <Menu.Item key="1" onClick={handleRegisterClick}>
            Register
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  

  return (
    <div>
    <Tooltip title="Login, or Register to Login. Then, this place also enables you to manage your datapreferences" mouseEnterDelay={1.5} placement="bottom">
      <Dropdown 
        overlay={menu}
        trigger={['click']}>
        <Button>
          {userName || 'Guest'}
         
          <DownOutlined />
        </Button>

      </Dropdown>
      </Tooltip>

      <MarketAlerts isVisible={isDatabaseModalVisible} onClose={handleDatabaseModalClose} />

      {isPreferencesModalVisible && (
        <PreferencesModal
          userId={getUserId()}
          onClose={handlePreferencesModalClose}
        />
      )}

{isClauseModalVisible && (
  <ClauseManagement
    isVisible={isClauseModalVisible}
    onClose={handleClauseModalClose}
  />
)}


      <User
        defaultTab={modalState.defaultTab}
        setToken={setToken}
        visible={modalState.isLoginRegisterModalVisible}
        setVisible={(isVisible) => setModalState({...modalState, isLoginRegisterModalVisible: isVisible})}
      />

      <CategoryPreferencesModal 
      isVisible={isCategoryPreferencesModalVisible} 
      setIsVisible={setIsCategoryPreferencesModalVisible}
      categoryPreferences={categoryPreferences}
      />

      <MaintenanceModal 
          isVisible={isMaintenanceModalVisible} 
          setIsVisible={setIsMaintenanceModalVisible} 
        />

      <BrokerAccessModal 
          isVisible={isBrokeraccessmodalVisible} 
          setIsVisible={setIsBrokeraccessmodalVisible} 
          onClose={handleBrokeraccessModalClose}
        />      



    </div>
  );
};

export default Account;

