import React, { useEffect, useState } from 'react';
import { Table, Modal, Tooltip, message } from 'antd';
import ThreeTablesModal from "./SearchResultModal";
import api from '../functions/api';
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';

const WidgetTable = ({ title, widgetcontentid }) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [isFullTableVisible, setIsFullTableVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);

  const toggleFullTableModal = () => {
    console.log("widget table toggle toggled")
    setIsFullTableVisible(!isFullTableVisible);
  };

  useEffect(() => {
    // console.log("widgetcontentid:", widgetcontentid);
    // console.log("isFullTableVisible:", isFullTableVisible);
    if (isFullTableVisible && widgetcontentid) {
      setLoading(true);
      api.get(`/widget_content_delivery?widgetcontentid=${widgetcontentid}`)
        .then(response => {
          const { data, headers_sequence } = response.data;
  
          // Normalize headers_sequence to lowercase for consistent access
          const normalizedHeaders = Object.keys(headers_sequence).reduce((acc, key) => {
            acc[key.toLowerCase()] = headers_sequence[key];
            return acc;
          }, {});
  
          // Normalize data keys to lowercase for consistency
          const normalizedData = data.map(item =>
            Object.keys(item).reduce((acc, key) => {
              acc[key.toLowerCase()] = item[key];
              return acc;
            }, {})
          );
  
          setData(normalizedData);
          
          // Sort headers according to their sequence and set them
          const sortedHeaders = Object.keys(normalizedHeaders)
            .sort((a, b) => normalizedHeaders[a] - normalizedHeaders[b]);
          setHeaders(sortedHeaders);
  
          setLoading(false);
          console.log("Normalized response in WidgetTable:", normalizedData);
        })
        .catch(error => {
          message.error('Failed to fetch table data.');
          console.error(error);
          setLoading(false);
        });
    }
  }, [isFullTableVisible, widgetcontentid]);
  
  
  
  

  const generateColumns = () => {
    // Ensure headers is an array; otherwise, return an empty array
    if (!Array.isArray(headers)) {
      console.error("Expected headers to be an array, but got:", headers);
      return [];
    }
  
    return headers.map(header => {
      const isTickerColumn = header.toLowerCase() === 'ticker';
  
      return {
        title: header.charAt(0).toUpperCase() + header.slice(1),
        dataIndex: header.toLowerCase(),
        key: header,
        render: (text, record) => isTickerColumn ? (
          <a 
            onClick={() => {
              setSelectedTicker(record[header.toLowerCase()]);
              setThreeTablesModalVisible(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            {text}
          </a>
        ) : (
          header === 'url' ? (
            <SimpleExternalLinkNewWindow clickedValue={text} url={record.url} />
          ) : text
        )
      };
    });
  };
  
  

  return (
    <>
      <div onClick={toggleFullTableModal} style={{ cursor: 'pointer' }}>
        <h3>{title}</h3>
      </div>

      <Modal
        title={title}
        visible={isFullTableVisible}
        onCancel={toggleFullTableModal}
        footer={null}
        width={1200}
        destroyOnClose // Ensure cleanup when the modal is closed
      >
        <Table
          dataSource={data}
          columns={generateColumns()}
          loading={loading}
          pagination={{ pageSize: 10 }}
          rowKey="id"
        />
      </Modal>

      {threeTablesModalVisible && (
        <ThreeTablesModal
          tickerValue={selectedTicker}
          open={threeTablesModalVisible}
          onClose={() => setThreeTablesModalVisible(false)}
        />
      )}
    </>
  );
};

export default WidgetTable;

// /// WidgetTable.jsx
// import React, { useEffect, useState, useRef } from 'react';
// import { Table, Modal, Tooltip } from 'antd';
// import ThreeTablesModal from "./SearchResultModal";
// import getUserId from '../functions/getUserId';
// import { useSelector } from 'react-redux';
// import SimpleExternalLinkNewWindow from '../functions/LinkFlex';

// const WidgetTable = ({ title, data, headersSequence, headersSequence2, datafields_thresholds }) => {
//   const [isFullTableVisible, setIsFullTableVisible] = useState(false);
//   const [selectedTicker, setSelectedTicker] = useState(null);
//   const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);
//   const [columns, setColumns] = useState([]);
//   const dataparameters = useSelector(state => state?.portfolio?.parametersData);

//   // console.log("Title ", title)
//   // console.log("headers sequence", headersSequence)
//   // console.log("headers sequence2", headersSequence2)
//   // console.log("datafield thold mapping", datafields_thresholds)

//   useEffect(() => {
//     if (data && headersSequence) {
//       const orderedColumns = Object.keys(headersSequence)
//         .sort((a, b) => headersSequence[a] - headersSequence[b])
//         .filter(key => key !== 'is_favorite' && !['url', 'Url', 'URL', 'id', 'ID'].includes(key)) // Direct exclusion here
        
//         .map(key => {

//           let columnTitle = key.charAt(0).toUpperCase() + key.slice(1);

//           let matchingParameter;

//           const column = {
//             title: columnTitle,
//             dataIndex: key,
//             key: key,
//             render: (text, record) => {
//               // Special render for the 'company' column
//               if (key === 'company') {
//                 return (
//                   <Tooltip title={text}>
//                     <div style={{ 
//                       maxWidth: '10ch',
//                       whiteSpace: 'nowrap',
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis'
//                     }}>{text}</div>
//                   </Tooltip>
//                 );
//               } else if (key === 'url') {
//                 // Special render for the 'url' column using the SimpleExternalLinkNewWindow component
//                 return <SimpleExternalLinkNewWindow clickedValue={text} url={record.url} />;
//               } else {
//                 // Default render with or without a tooltip
//                 return matchingParameter && matchingParameter.Tooltip ? (
//                   <Tooltip title={matchingParameter.Tooltip}>{text}</Tooltip>
//                 ) : (
//                   text
//                 );
//               }
//             },
//           };
  
//           // Add click event handler for ticker column
//           if (key === 'ticker') {
//             column.render = (text, record) => (
//               // Add a div with a specific class name or data attribute around the ticker text
//               <div className="ticker-column" data-ticker={record.ticker}>{text}</div>
//             );
          
//             column.onCell = (record) => ({
//               onClick: () => {
//                 setSelectedTicker(record.ticker);
//                 setThreeTablesModalVisible(true);
//               },
//               style: { cursor: 'pointer' },
//             });
//           }

//           if (key === 'sentiment') {
//             column.render = (text, record) => {
//               const vdpMatchingParameter = dataparameters?.VDP_dataset_json?.find(param => param.Vector_datafield === text);
          
//               let content = vdpMatchingParameter?.Label ? vdpMatchingParameter.Label : text;
//               let element = <span>{content}</span>; // Default case, no link
          
//               // If there's an external link, wrap the content with it
//               if (vdpMatchingParameter?.ExternalLink) {
//                 element = (
//                   <SimpleExternalLinkNewWindow clickedValue={content} url={vdpMatchingParameter.ExternalLink} />
//                 );
//               }
          
//               // Wrap with Tooltip if there's tooltip text, else return the element as is
//               return vdpMatchingParameter?.Tooltip ? (
//                 <Tooltip title={vdpMatchingParameter.Tooltip}>{element}</Tooltip>
//               ) : element;
//             };
//           }
        
          
//           return column;
//         });
//       setColumns(orderedColumns);
//     }
// }, [data, headersSequence, headersSequence2, dataparameters, datafields_thresholds]);
  

//   const toggleFullTableModal = () => {
//     setIsFullTableVisible(!isFullTableVisible);
//   };

//   // Adjust the inline style in the JSX
// const renderSingleRecord = (record) => {
//   // Create an array of JSX elements containing the data
//   return (
//     <div style={{ paddingLeft: '20px' }}> {/* Add padding here */}
//       {Object.keys(record).map(key => {
//         // Exclude 'ticker' and 'company' from this list
//         if (key !== 'ticker' && key !== 'company') { 
//           return <div key={key}>{`${key.replace(/_/g, ' ').toUpperCase()}: ${record[key]}`}</div>;
//         }
//         return null;
//       })}
//     </div>
//   );
// };

// // Adjusted logic to keep "Filter:" in the main part of the title
// let titleMainPart = title;
// let titleFilterPart = '';
// if (title.includes('Filter:')) {
//   const indexOfFilter = title.indexOf('Filter:');
//   titleMainPart = title.substring(0, indexOfFilter + 'Filter:'.length);
//   titleFilterPart = title.substring(indexOfFilter + 'Filter:'.length);
// }

//   const singleRecordDisplay = data && data.length === 1 ? renderSingleRecord(data[0]) : null;

//   return (
//     <>
//       <div 
//       onClick={toggleFullTableModal} 
//       style={{ 
//         cursor: 'pointer', 
//         overflowX: 'hidden' 
//       }} // Hide the overflow here
//       className="widget-table-container"
//     >
//       {/* <h3>{title}</h3> */}
//       <h3>{titleMainPart}</h3>
//       {titleFilterPart && <div style={{ fontSize: 'smaller' }}>{titleFilterPart}</div>}
    
//     </div>

//       <Modal
//         title={title}
//         visible={isFullTableVisible}
//         onCancel={() => setIsFullTableVisible(false)}
//         footer={null}
//         width={1200}
//       >
       
//        <Table dataSource={data} columns={columns} pagination={{ pageSize: 10 }}
//   onRow={(record, rowIndex) => {
//     return {
//       onClick: event => {
//         // Check if the click originated from a 'ticker' column to prevent opening the URL
//         if (event.target.closest('.ticker-column') || event.target.dataset.ticker) {
//           // If the click is inside a 'ticker' column, do nothing here
//           // The onCell click handler for the 'ticker' column will handle the logic
//           return;
//         }
//         // Check if the URL exists in the record and is not just an empty string
//         if (record.url && record.url.trim() !== "") {
//           event.preventDefault(); // Prevent default if it's an anchor tag to avoid navigation
//           window.open(record.url, '_blank'); // Open the URL in a new window
//         }
//       }
//     };
//   }}
// />

//       </Modal>

//       {threeTablesModalVisible && (
//         <ThreeTablesModal
//           userId={getUserId()}
//           tickerValue={selectedTicker}
//           open={threeTablesModalVisible}
//           onClose={() => setThreeTablesModalVisible(false)}
//         />
//       )}
//     </>
//   );
// };

// export default WidgetTable;

