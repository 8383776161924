
import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Collapse, Row, Col } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import '../css/ESG_MainTable.css'; // Import custom CSS
import ESG_Details from './ESG_Details';
import { getColorForValue } from '../functions/ESG_ColorForValue';
import ESG_ETFDetails from './ESG_ETFDetails';
import api from '../functions/api';

const { Panel } = Collapse;

function ESG_MainTable({
  dataSet = [],
  metadata = [],
  handleCellClick,
  dataparametersSet = { DP_dataset_json: [] },
  notFoundDataset = [],
  holdingsData = [],
  responseData = []
  // etfEsgHighlights = []
}) {
  // console.log("Initial props:", { dataSet, metadata, dataparametersSet, notFoundDataset, holdingsData, responseData });

  const [stockSummary, setStockSummary] = useState({});
  const [etfSummaries, setEtfSummaries] = useState({});
  const [selectedTicker, setSelectedTicker] = useState(''); // State for the selected ticker
  const [selectedDetails, setSelectedDetails] = useState({}); // State for the selected details
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);

  const [dataFieldMapping, setDataFieldMapping] = useState({});
  const [sourceTableMapping, setSourceTableMapping] = useState({});

  const [etfDetailsModalVisible, setEtfDetailsModalVisible] = useState(false); // State for modal visibility
  const [selectedEtfData, setSelectedEtfData] = useState({}); // State for selected ETF data
  const [etfEsgHighlights, setEtfEsgHighlights] = useState({});

  
  const handleEtfClick = async (record) => {
    const { ETF } = record; // Extract ETF ticker
  
    try {
      // Make the API call to fetch the ETF ESG data
      const response = await api.post(`/single_ETF_esg_view?ticker=${ETF}`, null, {
        headers: {
          'Content-Type': 'application/json',
        },
        noAuth: true,
      });
  
      const etfData = {
        ticker: ETF,
        etfName: response.data.etf_name,
        holdings: response.data.esg_data_holdings.holdings.map(holding => ({
          Ambition: holding.Ambition || 'N/A',
          Decarbon_target: holding.Decarbon_target || 'N/A',
          Environment_controversy: holding.Environment_controversy || 'N/A',
          Governance_controversy: holding.Governance_controversy || 'N/A',
          Industry_ranking: holding.Industry_ranking || 'N/A',
          MSCI_ESG_Rating: holding.MSCI_ESG_Rating || 'N/A',
          Refinitiv_ESG_score: holding.Refinitiv_ESG_score || 0,
          Refinitiv_environment_score: holding.Refinitiv_environment_score || 0,
          Refinitiv_governance_score: holding.Refinitiv_governance_score || 0,
          Refinitiv_social_score: holding.Refinitiv_social_score || 0,
          SA_ESG_RR: holding.SA_ESG_RR || 'N/A',
          Social_controversy: holding.Social_controversy || 'N/A',
          Target_year: holding.Target_year || 'N/A',
          company: holding.company || 'Unknown Company',
          holdingPercent: holding.holdingPercent || 0.0,
          industry: holding.industry || 'Unknown Industry',
          overall_ratings_complete: holding.overall_ratings_complete || false,
          overall_ratings_green_lightgreen: holding.overall_ratings_green_lightgreen || false,
          ratings_not_green_lightgreen: holding.ratings_not_green_lightgreen || false,
          ticker: holding.ticker || 'Unknown Ticker',
          sp_esg_score_color: holding.sp_esg_score_color || 'N/A',
        }))
      };
  
      setSelectedEtfData(etfData);
      setEtfDetailsModalVisible(true);

      // Capture additional highlights and group them into an object
      const Etf_Esg_Highlights = {
        total_all_green_lightgreen: response.data.esg_data_holdings.total_all_green_lightgreen,
        total_with_complete_data: response.data.esg_data_holdings.total_with_complete_data,
        total_with_issues: response.data.esg_data_holdings.total_with_issues,
        total_with_overall_ratings_complete: response.data.esg_data_holdings.total_with_overall_ratings_complete,
        total_with_overall_ratings_green_lightgreen: response.data.esg_data_holdings.total_with_overall_ratings_green_lightgreen,
        total_with_ratings_not_green_lightgreen: response.data.esg_data_holdings.total_with_ratings_not_green_lightgreen,
        total_with_any_param_issues: response.data.esg_data_holdings.total_with_any_param_issues,            // New parameter
        total_with_red_issues_in_ratings: response.data.esg_data_holdings.total_with_red_issues_in_ratings,  // New parameter
        total_with_red_issues_in_any_param: response.data.esg_data_holdings.total_with_red_issues_in_any_param,  // New parameter
        percent_complete_data: response.data.esg_data_holdings.percent_complete_data,                        // New percentage parameter
        percent_all_green_lightgreen: response.data.esg_data_holdings.percent_all_green_lightgreen,          // New percentage parameter
        percent_with_issues: response.data.esg_data_holdings.percent_with_issues,                            // New percentage parameter
        percent_overall_ratings_complete: response.data.esg_data_holdings.percent_overall_ratings_complete,  // New percentage parameter
        percent_overall_ratings_green_lightgreen: response.data.esg_data_holdings.percent_overall_ratings_green_lightgreen,  // New percentage parameter
        percent_ratings_not_green_lightgreen: response.data.esg_data_holdings.percent_ratings_not_green_lightgreen,  // New percentage parameter
        percent_any_param_issue: response.data.esg_data_holdings.percent_any_param_issue,                    // New percentage parameter
        percent_red_issue_in_ratings: response.data.esg_data_holdings.percent_red_issue_in_ratings,          // New percentage parameter
        percent_red_issue_any_param: response.data.esg_data_holdings.percent_red_issue_any_param,            // New percentage parameter
        total_holding_percent: response.data.esg_data_holdings.total_holding_percent                         // New total percentage parameter
    };
    
    

    // console.log(" setting Etf_Esg_Highlight", Etf_Esg_Highlights)
    setEtfEsgHighlights({ ...Etf_Esg_Highlights });

    } catch (error) {
      console.error("Error fetching ETF ESG data:", error);
    }
  };
  



  const handleTickerClick = (record) => {
    setSelectedTicker(record.ticker);
    setSelectedDetails(record);
    setDetailsModalVisible(true);
  };

  useEffect(() => {
    setStockSummary(responseData.stock_summary || {});
    setEtfSummaries(responseData.etf_summaries || {});
  }, [responseData, dataSet]);

  const getValidValue = (value, defaultValue = 0) => {
    return value !== undefined ? value : defaultValue;
  };

  useEffect(() => {
    console.log("Data parameters set:", dataparametersSet);
    if (!dataparametersSet || !dataparametersSet.DP_dataset_json) {
      console.error("Invalid data parameters set");
      return;
    }
    const dataFieldMap = {};
    const sourceTableMap = {};

    dataparametersSet.DP_dataset_json.forEach((param) => {
      dataFieldMap[param.DataField] = param;
      sourceTableMap[param.DataField] = param.SourceTable;
    });

    setDataFieldMapping(dataFieldMap);
    setSourceTableMapping(sourceTableMap);

    console.log("Data Field Mapping Initialized:", dataFieldMap);
  }, [dataparametersSet]);

  // Generate all possible keys from the dataset
  let allKeys = [];
  dataSet.forEach((record) => {
    allKeys = [...new Set([...allKeys, ...Object.keys(record)])];
  });

  const potential_etf_tickers_with_only_indirect_hits =
    responseData?.potential_etf_tickers_with_only_indirect_hits || [];

  let filteredKeys = [];
  if (dataparametersSet && dataparametersSet.DP_dataset_json) {
    filteredKeys = dataparametersSet.DP_dataset_json
      .filter((param) => param.esg_summary_order !== null && param.esg_summary_order !== 0 && param.esg_summary_order !== '0.00') // Only include valid esg_summary_order
      .map((param) => param.DataField);
  }

  const keys = allKeys.filter(
    (key) => key !== 'ticker' && key !== 'company' && filteredKeys.includes(key)
  );

  const filteredDataSet = dataSet.filter((record) => {
    return keys.some(
      (key) => record[key] !== null && record[key] !== '' && record[key] !== 'n/a'
    );
  });

  let columns = [];

  if (filteredDataSet.length > 0 && metadata) {
    keys.sort((a, b) => {
      const sourceA = sourceTableMapping[a] || '';
      const sourceB = sourceTableMapping[b] || '';
      const labelA = dataFieldMapping[a]?.Label || a;
      const labelB = dataFieldMapping[b]?.Label || b;

      if (sourceA === sourceB) {
        return labelA.localeCompare(labelB);
      }
      return sourceA.localeCompare(sourceB);
    });

    columns = [
      {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        sorter: (a, b) => a.ticker.localeCompare(b.ticker),
        showSorterTooltip: false,
        align: 'center',
        render: (text, record) => (
          <Tooltip title={`View details for ${text}`}>
            <span
              onClick={() => handleTickerClick(record)}
              style={{ cursor: 'pointer', color: '#1890ff' }}
            >
              {text}
            </span>
          </Tooltip>
        ),
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        sorter: (a, b) => a.company.localeCompare(b.company),
      },
      ...keys.map((key) => ({
        title: (
          <div style={{ textAlign: 'center' }}>
            <div className="esg-custom-header">
              <Tooltip title={metadata[key]?.Tooltip} mouseEnterDelay={1.5}>
                <span>{metadata[key]?.Label || key}</span>
              </Tooltip>
            </div>
            <div className="esg-source-table">
              {sourceTableMapping[key]
                ? sourceTableMapping[key].replace('_Data', '')
                : ''}
            </div>
          </div>
        ),
        showSorterTooltip: false,
        dataIndex: key,
        key,
        render: (text, record) => {
          let className = '';
          if (text !== null && text !== undefined) {
            const color = getColorForValue(key, text, dataFieldMapping); // Updated function call
            if (color) {
              className = `cell-${color}`;
            }
          }
          return (
            <div className={className} onClick={() => handleCellClick(record, key)}>
              {text || '-'}
            </div>
          );
        },
        sorter: (a, b) => a[key] - b[key],
      })),
    ];
  }

  const notFoundTickers = (notFoundDataset || [])
    .map((item) => item.ticker)
    .join(', ');
  const sortedNotFoundTickers = (notFoundDataset || [])
    .map((item) => item.ticker)
    .sort()
    .join(', ');

  const filteredOutColumns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      sorter: (a, b) => a.ticker.localeCompare(b.ticker),
      showSorterTooltip: false,
      align: 'center',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: (a, b) => a.company.localeCompare(b.company),
    },
  ];

  const filteredOutRecords = dataSet
    ? dataSet.filter((record) => {
        return !keys.some(
          (key) => record[key] !== null && record[key] !== '' && record[key] !== 'n/a'
        );
      })
    : [];

  const flattenedHoldingsData = Array.isArray(holdingsData)
    ? holdingsData.reduce((acc, etf) => {
        const { ETF, ETFName, Holdings_ESG_Data } = etf;
        Holdings_ESG_Data.forEach((holding) => {
          acc.push({ ...holding, ETF, ETFName });
        });
        return acc;
      }, [])
    : [];

  // Create columns for the ETF holdings data
  const holdingsColumns = [
    {
      title: 'ETF',
      dataIndex: 'ETF',
      key: 'etf',
      render: (text, record) => (
        <Tooltip title={`ETF: ${record.ETF}`}>
          <span
            onClick={() => handleEtfClick(record)} // Use handleEtfClick function
            style={{ cursor: 'pointer', color: '#1890ff' }}
          >
            {text}
          </span>
        </Tooltip>
      ),
      align: 'center',
    },
    
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text, record) => (
        <Tooltip title={`Holding in ETF ${record.ETFName}`}>
          <span
            onClick={() => handleTickerClick(record)}
            style={{ cursor: 'pointer', color: '#1890ff' }}
          >
            {`In ETF ${record.ETF}: ${text}`}
          </span>
        </Tooltip>
      ),
      align: 'center',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record) => (
        <Tooltip title={`Holding in ETF ${record.ETFName}`}>
          <div>{text}</div>
        </Tooltip>
      ),
    },
    ...keys.map((key) => ({
      title: metadata[key]?.Label || key,
      dataIndex: key,
      key,
      render: (text, record) => {
        let className = '';
        if (text !== null && text !== undefined) {
          const color = getColorForValue(key, text, dataFieldMapping);
          if (color) {
            className = `cell-${color}`;
          }
        }
        return (
          <div className={className} onClick={() => handleCellClick(record, key)}>
            {text || '-'}
          </div>
        );
      },
    })),
  ];

  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }} id="ESG_Maintable">
      {(stockSummary?.total_stocks || etfSummaries?.total_stocks) && (
        <Collapse defaultActiveKey={['1']} style={{ marginBottom: '20px' }}>
          <Panel header="Summary" key="1">
            {stockSummary?.total_stocks && (
              <>
                <h4>Stock Summary</h4>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <p>Total Stocks: {getValidValue(stockSummary?.total_stocks)}</p>
                  </Col>
                  <Col span={12}>
                    <p>Valid Stocks Count: {getValidValue(stockSummary?.valid_stocks_count)}</p>
                  </Col>
                  <Col span={12}>
                    <p>
                      Percentage Valid Stocks:{' '}
                      {getValidValue(stockSummary?.percentage_valid_stocks).toFixed(2)}%
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>Stocks 'All Green': {getValidValue(stockSummary?.valid_green_lightgreen_stocks)}</p>
                  </Col>
                  <Col span={12}>
                    <p>Stocks 'With Issues': {getValidValue(stockSummary?.valid_not_green_lightgreen_stocks)}</p>
                  </Col>
                </Row>
              </>
            )}

            {etfSummaries?.total_stocks && (
              <>
                <h4>ETF Summaries</h4>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <p>Total Stocks: {getValidValue(etfSummaries?.total_stocks)}</p>
                  </Col>
                  <Col span={12}>
                    <p>Valid Stocks Count: {getValidValue(etfSummaries?.valid_stocks_count)}</p>
                  </Col>
                  <Col span={12}>
                    <p>
                      Percentage Valid Stocks:{' '}
                      {getValidValue(etfSummaries?.percentage_valid_stocks).toFixed(2)}%
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>Stocks 'All Green': {getValidValue(etfSummaries?.valid_green_lightgreen_stocks)}</p>
                  </Col>
                  <Col span={12}>
                    <p>Stocks 'With Issues': {getValidValue(etfSummaries?.valid_not_green_lightgreen_stocks)}</p>
                  </Col>
                </Row>
              </>
            )}
          </Panel>
        </Collapse>
      )}

      {filteredDataSet.length > 0 ? (
        <Collapse defaultActiveKey={['1']} style={{ marginBottom: '20px' }}>
          <Panel header={`ESG values from stocks (${filteredDataSet.length})`} key="1">
            <Table
              dataSource={filteredDataSet} // Use filtered dataset
              columns={columns}
              pagination={true}
              size="small"
              rowClassName={() => 'esgmaintable-custom-font'}
            />
          </Panel>
        </Collapse>
      ) : (
        <div>No stocks found for which ESG data is available.</div>
      )}

      {detailsModalVisible && (
        <ESG_Details
          visible={detailsModalVisible}
          onClose={() => setDetailsModalVisible(false)}
          ticker={selectedTicker}
          details={selectedDetails}
        />
      )}

      {flattenedHoldingsData.length > 0 && (
        <Collapse defaultActiveKey={['1']} style={{ marginBottom: '20px' }}>
          <Panel header={`ESG values from holdings in ETFs (${flattenedHoldingsData.length})`} key="2">
            <Table
              dataSource={flattenedHoldingsData} // Use the flattened ETF holdings dataset
              columns={holdingsColumns}
              pagination={true}
              size="small"
              rowClassName={() => 'esgmaintable-custom-font'}
            />
          </Panel>
        </Collapse>
      )}

{etfDetailsModalVisible && (
  <ESG_ETFDetails
    visible={etfDetailsModalVisible}
    onClose={() => setEtfDetailsModalVisible(false)} // Close modal handler
    etfData={selectedEtfData} // Pass the selected ETF data
    etfEsgHighlights={etfEsgHighlights} // Pass the highlights data
  />
)}



      {potential_etf_tickers_with_only_indirect_hits.length > 0 && (
        <Collapse style={{ marginTop: '20px', fontSize: '14px' }}>
          <Panel
            header={`Potential ETF tickers which have not been validated (${potential_etf_tickers_with_only_indirect_hits.length})`}
            key="3"
          >
            <div>{potential_etf_tickers_with_only_indirect_hits.join(', ')}</div>
          </Panel>
        </Collapse>
      )}

      {Array.isArray(filteredOutRecords) && filteredOutRecords.length > 0 && (
        <Collapse style={{ marginTop: '20px' }}>
          <Panel header={`Companies for which no ESG data is available (${filteredOutRecords.length})`} key="1">
            <Table
              dataSource={filteredOutRecords} // Use filtered out records
              columns={filteredOutColumns}
              pagination={false}
              size="small"
              rowClassName={() => 'esgmaintable-custom-font'}
            />
          </Panel>
        </Collapse>
      )}

      {notFoundDataset.length > 0 && (
        <Collapse style={{ marginTop: '20px', fontSize: '14px' }}>
          <Panel header={`Tickers for which the assettype or company has not been determined (${notFoundDataset.length})`} key="2">
            <div>{notFoundTickers}</div>
          </Panel>
        </Collapse>
      )}
    </div>
  );
}

export default ESG_MainTable;

