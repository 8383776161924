
import React, { useEffect, useState } from 'react';
import { Modal, List, Spin, Tooltip } from 'antd';

import api from '../functions/api';
import getUserId from '../functions/getUserId';
import ThreeTablesModal from '../components/SearchResultModal';
import PerformanceChart from '../components/PerformanceChart'; 
import PieChartComponent from '../components/PieCharts';
import { useSelector } from 'react-redux';
import ESG_ETFDetails from './ESG_ETFDetails';



const ETF_searchresult = ({ visible, onClose, etfSymbol, etfName }) => {
  const [selectedEtf, setSelectedEtf] = useState({ holdings: [] });

  // const [selectedEtf, setSelectedEtf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [overlapDetails, setOverlapDetails] = useState([]);
  const [totalEtfOverlap, setTotalEtfOverlap] = useState(0);
  const [totalPortfolioAmountOverlap, setTotalPortfolioAmountOverlap] = useState(0);
  const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState('');
  const [esgData, setEsgData] = useState([]);
  const [tickerQuotetype, setTickerQuotetype] = useState([]);
  const [etfEsgHighlights, setEtfEsgHighlights] = useState({});

  console.log("etfSymbol ", etfSymbol)


  const totalPercent = 
  selectedEtf && 
  Array.isArray(selectedEtf.holdings) && 
  selectedEtf.holdings.length > 0 && 
  Array.isArray(selectedEtf.holdings[0]) && 
  selectedEtf.holdings[0].length > 0
    ? (selectedEtf.holdings[0].reduce((acc, item) => acc + (item?.holdingPercent || 0), 0) * 100).toFixed(2)
    : '0.00';


   
  const logEquityHoldings = () => {
    // Ensure tickerQuotetype is set correctly before filtering
    console.log("Current tickerQuotetype state:", tickerQuotetype);
  
    const equityHoldings = tickerQuotetype.filter(t => t.Quotetype === 'EQUITY');
    console.log("Equity Holdings:", equityHoldings);
  };
  
  const isEquity = (symbol) => {
    // Ensure the symbol is not undefined or null before proceeding
    if (!symbol) return false;
  
    return tickerQuotetype.some(
      (t) => t.ticker && t.ticker.toLowerCase() === symbol.toLowerCase() && t.Quotetype === 'EQUITY'
    );
  };
  
  
  
  // Access DP_dataset_json from Redux
  const DP_dataset_json = useSelector(state => state.portfolio?.parametersData?.DP_dataset_json);

  const extractTickers = (holdings) => {
    const tickers = [];
  
    // Check if the first element is an array and iterate over it
    if (Array.isArray(holdings[0])) {
      holdings[0].forEach((item) => {
        if (item.symbol) {
          tickers.push(item.symbol);
        }
      });
    }
  
    return tickers;
  };
  

  const fetchEsgData = async (tickers) => {
    try {
      const response = await api.post('/pf_esg_view', { tickers });

      // console.log("EsgData", response)

      if (response.status === 200) {
        return response.data;
      } else {
        console.error('Error fetching ESG data');
        return null;
      }
    } catch (error) {
      console.error('Error fetching ESG data:', error);
      return null;
    }
  };
  
  const extractEsgData = (esgData) => {
    return esgData.map(item => ({
    // return esgData.data.map(item => ({
      ticker: item.ticker,
      MSCI_ESG_Rating: item.MSCI_ESG_Rating,
      Refinitiv_ESG_score: item.Refinitiv_ESG_score,
      SA_ESG_RR: item.SA_ESG_RR
    }));
  };

  
  const getColorForValue = (dataField, value, dpDataset) => {
    const fieldInfo = dpDataset.find(item => item.DataField === dataField);
  
    if (!fieldInfo || value === undefined || value === null) return 'grey'; // Return grey for missing data
  
    const ranges = {
      Red: fieldInfo.Red ? fieldInfo.Red.split(', ').map(v => v.trim()) : [],
      Orange: fieldInfo.Orange ? fieldInfo.Orange.split(', ').map(v => v.trim()) : [],
      Yellow: fieldInfo.Yellow ? fieldInfo.Yellow.split(', ').map(v => v.trim()) : [],
      Lightgreen: fieldInfo.Lightgreen ? fieldInfo.Lightgreen.split(', ').map(v => v.trim()) : [],
      Green: fieldInfo.Green ? fieldInfo.Green.split(', ').map(v => v.trim()) : [],
    };
  
    // Check if value is a string and falls within the defined ranges
    for (const [color, range] of Object.entries(ranges)) {
      if (range.includes(value)) {
        return color.toLowerCase();
      }
    }
  
    // Handling numeric ranges for other fields
    if (!isNaN(value)) {
      const numericValue = parseFloat(value);
      const numericRanges = {
        Red: fieldInfo.Red ? fieldInfo.Red.split('-').map(Number) : null,
        Orange: fieldInfo.Orange ? fieldInfo.Orange.split('-').map(Number) : null,
        Yellow: fieldInfo.Yellow ? fieldInfo.Yellow.split('-').map(Number) : null,
        Lightgreen: fieldInfo.Lightgreen ? fieldInfo.Lightgreen.split('-').map(Number) : null,
        Green: fieldInfo.Green ? fieldInfo.Green.split('-').map(Number) : null,
      };
  
      for (const [color, range] of Object.entries(numericRanges)) {
        if (range && numericValue >= range[0] && numericValue <= range[1]) {
          return color.toLowerCase();
        }
      }
    }
  
    return 'grey'; // Return grey for any other cases
  };
  
  
  
  useEffect(() => {
    const fetchEtfData = async () => {
      setLoading(true);
      const userId = getUserId();

      try {
        const response = await api.post('/etf_search', null, {
          params: {
            userid: userId,
            etf_ticker: etfSymbol,
          },
        });

        // Log the entire API response for debugging purposes
      console.log("API response data: ", response.data);

        if (response.status === 200) {
          
          
          const holdings = Array.isArray(response.data.holdings) ? response.data.holdings : [[]]; // Ensure holdings is a valid array with a nested empty array
          
          // Filter out any null or invalid entries from holdings
        const validHoldings = Array.isArray(response.data.holdings)
          ? response.data.holdings.filter(holding => holding !== null && typeof holding === 'object')
          : [];

      setSelectedEtf({
        ...response.data,
        holdings: validHoldings, // Use sanitized holdings data
      });
                    
          setOverlapDetails(response.data.overlap_details || []);
          setTotalEtfOverlap(response.data.total_etf_overlap || 0);
          setTotalPortfolioAmountOverlap(response.data.total_portfolio_amount_overlap || 0);
          console.log('Response Data:', response.data);

          setTickerQuotetype(response.data.ticker_quotetype_in_etfholdings || []);

          // Call the helper function to log equity holdings
          logEquityHoldings();

          const tickers = extractTickers(holdings); // Pass the holdings array
          console.log('Extracted Tickers:', tickers);

          if (tickers.length > 0) {
            const esgResponse = await fetchEsgData(tickers);
            if (esgResponse) {
              const esgData = extractEsgData(esgResponse.data);
              console.log("esg data for this etf ", esgData);
              setEsgData(esgData);
            }
          }
        } else {
          console.error('ETF or ETF holdings not found');
        }
      } catch (error) {
        console.error('Error fetching ETF holdings and overlap:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEtfData();
  }, [etfSymbol]);

  
  const getOverlapDetails = (symbol) => {
    const overlap = overlapDetails.find(detail => detail.symbol.toLowerCase() === symbol.toLowerCase());
    return overlap ? ` Your current exposure in ${overlap.holding_name} is EUR ${overlap.total_amount_exposure}` : '';
  };
  
  // const getOverlapDetails = (symbol) => {
  //   const overlap = overlapDetails.find(detail => detail.symbol === symbol);
  //   return overlap ? ` Your current exposure in ${overlap.holding_name} is EUR ${overlap.total_amount_exposure}` : '';
  // };

// Define the formatPerformance function
const formattedPerformanceData = selectedEtf && selectedEtf.etf_performance && selectedEtf.etf_performance.annualTotalReturns
  ? selectedEtf.etf_performance.annualTotalReturns.returns.map(item => ({
      year: item.year,
      annualValue: (item.annualValue * 100).toFixed(2),  // Round to 2 decimal places
      formattedValue: `${item.year} : ${(item.annualValue * 100).toFixed(2)}%`  // Round to 2 decimal places
    }))
  : [];
  

  // Log the formatted performance data to verify
  // console.log('Formatted Performance Data:', formattedPerformanceData);
  

// Extract and format the YTD return value
const ytdReturn = selectedEtf && selectedEtf.etf_keystats && selectedEtf.etf_keystats.ytdReturn
  ? (selectedEtf.etf_keystats.ytdReturn * 100).toFixed(2)
  : null;

const fiveYearAvgReturn = selectedEtf && selectedEtf.etf_keystats && selectedEtf.etf_keystats.fiveYearAverageReturn
  ? (selectedEtf.etf_keystats.fiveYearAverageReturn * 100).toFixed(2)
  : null;

const threeYearAvgReturn = selectedEtf && selectedEtf.etf_keystats && selectedEtf.etf_keystats.threeYearAverageReturn
  ? (selectedEtf.etf_keystats.threeYearAverageReturn * 100).toFixed(2)
  : null;

  const yieldValue = selectedEtf && selectedEtf.etf_keystats && selectedEtf.etf_keystats.yield
    ? (selectedEtf.etf_keystats.yield * 100).toFixed(2).replace('.', ',')
    : null;

    const formatSectors = (sectors) => {
        if (!sectors || typeof sectors !== 'object') return [];
        return Object.entries(sectors)
          .filter(([key, value]) => value !== 0)
          .map(([key, value]) => ({
            name: key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase()),
            value: Math.round(value * 100)
          }));
      };
      
    const sectors = selectedEtf && selectedEtf.etf_sectorweighings ? formatSectors(selectedEtf.etf_sectorweighings[etfSymbol] || {}) : [];
      


// console.log('Sectors Data:', selectedEtf && selectedEtf.etf_sectorweighings ? selectedEtf.etf_sectorweighings[etfSymbol] : 'No sector data');

  const expenseRatio = selectedEtf && selectedEtf.etf_fund_profile && selectedEtf.etf_fund_profile.feesExpensesInvestment
    ? (selectedEtf.etf_fund_profile.feesExpensesInvestment.annualReportExpenseRatio * 100).toFixed(2).replace('.', ',')
    : null;

  const previousClose = selectedEtf && selectedEtf.etf_summarydetail && selectedEtf.etf_summarydetail.previousClose
    ? `${selectedEtf.etf_summarydetail.currency} ${selectedEtf.etf_summarydetail.previousClose}`
    : null;

  return (
    <>
      <Modal
        title={
            <div>
              ETF Details: {etfSymbol}
              <div style={{ fontSize: '14px', color: 'grey' }}>{etfName}</div>
            </div>
          }
        visible={visible}
        onCancel={onClose}
        footer={null}
        width={1000}
      >
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Spin />
          </div>
        ) : selectedEtf ? (
          <>

            


{!loading && selectedEtf?.etf_summarydetail && (
  <p>Previous Close: {selectedEtf.etf_summarydetail.currency} {selectedEtf.etf_summarydetail.previousClose}</p>
)}


            
            
            {/* Main Holdings Section */}
          
            {/* {console.log('selectedEtf:', selectedEtf)}
            {console.log('selectedEtf.holdings:', selectedEtf.holdings)}
            {console.log('selectedEtf.holdings.length:', selectedEtf.holdings ? selectedEtf.holdings.length : 'No holdings')}
            {console.log('selectedEtf.holdings[0].holdings:', selectedEtf.holdings && selectedEtf.holdings[0].holdings)}
            {console.log('selectedEtf.holdings[0].holdings.length:', selectedEtf.holdings && selectedEtf.holdings[0].holdings ? selectedEtf.holdings[0].holdings.length : 'No sub-holdings')} */}

            {!loading && selectedEtf && selectedEtf.holdings && Array.isArray(selectedEtf.holdings[0]) && selectedEtf.holdings[0].length > 0 ? (
            // {selectedEtf?.holdings?.length > 0 && Array.isArray(selectedEtf.holdings[0]) && selectedEtf.holdings[0].length > 0 ? (

            // {selectedEtf && Array.isArray(selectedEtf.holdings) && selectedEtf.holdings.length > 0 && Array.isArray(selectedEtf.holdings[0]) && selectedEtf.holdings[0].length > 0 ? (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      {!loading && selectedEtf?.holdings && Array.isArray(selectedEtf.holdings) && selectedEtf.holdings.length > 0 && (
  <h3>Main Holdings ({selectedEtf.holdings[0]?.length || 0}, {totalPercent}% of ETF total)</h3>
)}


      {/* <h3>Main Holdings ({selectedEtf?.holdings?.[0]?.length || 0}, {totalPercent}% of ETF total)</h3> */}

        {/* <h3>Main Holdings ({selectedEtf.holdings[0].length}, {totalPercent}% of ETF total)</h3> */}
        {totalEtfOverlap > 0 && (
          <p>
            <strong>
              ETF holdings overlap with Portfolio: <span style={{ color: 'red' }}>{Math.round(totalEtfOverlap * 100) / 100}%</span><br />
              Total Portfolio value overlapping ETF: <span style={{ color: 'red' }}>EUR {Math.round(totalPortfolioAmountOverlap * 100) / 100}</span>
            </strong>
          </p>
        )}
      </div>

      <List
          dataSource={Array.isArray(selectedEtf?.holdings) && Array.isArray(selectedEtf.holdings[0]) ? selectedEtf.holdings[0] : []}
          renderItem={(item) => {
            if (!item) return null;
            const esgItem = esgData.find(esg => esg.ticker === item.symbol) || {};
            return (
              <List.Item style={{ padding: '4px 0' }} onClick={() => { setSelectedTicker(item.symbol); setThreeTablesModalVisible(true); }}>
                {`${item.holdingName} (${item.symbol}) - ${Math.round(100 * item.holdingPercent * 100) / 100}%`}
                {getOverlapDetails(item.symbol) && <span style={{ color: 'red', fontStyle: 'italic', marginLeft: '10px' }}>{getOverlapDetails(item.symbol)}</span>}
                {isEquity(item.symbol) && (
                  <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                    <Tooltip title={esgItem.MSCI_ESG_Rating ? `MSCI ESG Rating score is ${esgItem.MSCI_ESG_Rating}` : 'No data for MSCI ESG Rating'}>
                      <span style={{ width: '10px', height: '10px', backgroundColor: getColorForValue('MSCI_ESG_Rating', esgItem.MSCI_ESG_Rating, DP_dataset_json), display: 'inline-block', marginRight: '2px' }}></span>
                    </Tooltip>
                    <Tooltip title={esgItem.Refinitiv_ESG_score ? `Refinitiv ESG score is ${esgItem.Refinitiv_ESG_score}` : 'No data for Refinitiv ESG score'}>
                      <span style={{ width: '10px', height: '10px', backgroundColor: getColorForValue('Refinitiv_ESG_score', esgItem.Refinitiv_ESG_score, DP_dataset_json), display: 'inline-block', marginRight: '2px' }}></span>
                    </Tooltip>
                    <Tooltip title={esgItem.SA_ESG_RR ? `Sustainanalytics ESG Risk Rating score is ${esgItem.SA_ESG_RR}` : 'No data for Sustainanalytics ESG Risk Rating score'}>
                      <span style={{ width: '10px', height: '10px', backgroundColor: getColorForValue('SA_ESG_RR', esgItem.SA_ESG_RR, DP_dataset_json), display: 'inline-block' }}></span>
                    </Tooltip>
                  </div>
                )}
              </List.Item>
            );
          }}
        />


    </>
) : (
    <p>No holdings information</p>
)}


           

{sectors.length > 0 && (
  <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
    <div style={{ width: '48%' }}>
      <h3>Sectors</h3>
      <PieChartComponent data={sectors} title="Sector Weightings" />
    </div>
    <div style={{ width: '48%' }}>

      <h3>Performance</h3>


{selectedEtf.etf_performance && selectedEtf.etf_performance.annualTotalReturns ? (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
    {fiveYearAvgReturn !== null && !isNaN(fiveYearAvgReturn) && <div>5 year avg return: {fiveYearAvgReturn}%</div>}
    {threeYearAvgReturn !== null && !isNaN(threeYearAvgReturn) && <div>3 year avg return: {threeYearAvgReturn}%</div>}
    {ytdReturn !== null && !isNaN(ytdReturn) && <div>YTD return: {ytdReturn}%</div>}
                  
    </div>
    <PerformanceChart 
                      performanceData={formattedPerformanceData} 
                      showLegend={false} 
                      yAxisLabel="%" 
                    />
  </div>
) : (
  <p>No Performance information</p>
)}

      
    </div>
  </div>
)}


<div style={{ marginTop: '20px' }}>
  <h3>General</h3>
  {selectedEtf?.etf_keystats?.fundFamily && <p>Issuer: {selectedEtf.etf_keystats.fundFamily}</p>}
  {selectedEtf?.etf_summarydetail?.currency && <p>Currency: {selectedEtf.etf_summarydetail.currency}</p>}
  {selectedEtf?.etf_keystats?.category && <p>Category: {selectedEtf.etf_keystats.category}</p>}
  {selectedEtf?.etf_keystats?.totalAssets && <p>Total Assets: ${selectedEtf.etf_keystats.totalAssets.toLocaleString()}</p>}
  {yieldValue && <p>Yield: {yieldValue}%</p>}
  {expenseRatio && <p>Expense Ratio: {expenseRatio}%</p>}
</div>

          </>
        ) : null}
      </Modal>

      <ThreeTablesModal
        tickerValue={selectedTicker}
        open={threeTablesModalVisible}
        onClose={() => setThreeTablesModalVisible(false)}
        userId={getUserId()}
      />
    </>
  );
};

export default ETF_searchresult;

