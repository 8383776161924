import { useState, useEffect } from 'react';
import api from '../functions/api';

const useFetchDashboardStructure = () => {
  const [dashboardStructure, setDashboardStructure] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardStructure = async () => {
      try {
        const response = await api.post('/get_dashboard_table_structure');
        // console.log("dashboard_table_structure", response)
        setDashboardStructure(response.data.dashboard_structure);
      } catch (error) {
        setError('Error fetching dashboard structure');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardStructure();
  }, []);

  return { dashboardStructure, loading, error };
};

export default useFetchDashboardStructure;
