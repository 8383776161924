///TestCrafter.jsx
import React, { useState } from "react";
import { Modal, Button, Input, Form, message } from "antd";
import {
  extractHotspots,
  manageHotspotDefinitions,
  generateHotspotFormItems,
  fetchWhereFieldValues,
  testTextHotspot // Import the function from HotspotService
} from "../functions/HotspotService";

const TextCrafter = ({ dashboardStructure, initialText, onSave, onClose }) => {

  // console.log("dashboardstructure in Textcrafter ", dashboardStructure)

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [placeholders, setPlaceholders] = useState([]); // Store found placeholders
  const [hotspotDefinitions, setHotspotDefinitions] = useState({}); // Store hotspot definitions
  const [whereFieldValues, setWhereFieldValues] = useState({}); // Store WHERE condition values
  const [testResult, setTestResult] = useState(""); // New state for test result
  const [testResults, setTestResults] = useState({});
  const [sentData, setSentData] = useState({});

  const handleTestHotspot = async (
    placeholder,
    tableSelected,
    fieldSelected,
    whereFieldSelected,
    whereFieldValueSelected
  ) => {
     
        // Create the payload using the selected values
      const hotspotData = `<${tableSelected}, ${fieldSelected}${
          whereFieldSelected ? `, ${whereFieldSelected}` : ""
        }${whereFieldValueSelected ? `, ${whereFieldValueSelected}` : ""}>`;
      
      const payload = { text: hotspotData };
        // Set the sentData to track the payload
      setSentData(payload);
      
        try {
          const result = await testTextHotspot(payload); // Assuming testTextHotspot is the API call
          // console.log('Test result:', result);
          setTestResults((prevResults) => ({
            ...prevResults,
            [placeholder]: result,  // Store the result in testResults state
          }));
        } catch (error) {
          console.error('Error testing hotspot:', error);
          setTestResults((prevResults) => ({
            ...prevResults,
            [placeholder]: "Error testing hotspot.",
          }));
        }
  };
  
  // console.log("dashboardstructure in textcrafter", dashboardStructure);

  const handleReplacePlaceholder = (placeholder, replacement) => {
    const craftedText = form.getFieldValue("craftedText");
    const updatedText = craftedText.replace(`{${placeholder}}`, replacement);
    form.setFieldsValue({ craftedText: updatedText });
  };
  

  // Function to validate the text and extract hotspots
  const handleValidate = () => {
    const craftedText = form.getFieldValue("craftedText");
    const foundPlaceholders = extractHotspots(craftedText); // Extract hotspots using service

    if (foundPlaceholders.length > 0) {
      message.success(`Found hotspots: ${foundPlaceholders.join(", ")}`);
      setPlaceholders(foundPlaceholders); // Store found placeholders
    } else {
      message.info("No hotspots found.");
      setPlaceholders([]);
    }
  };

  // Handle updating the hotspot definition using the service
  const handleHotspotChange = (placeholder, key, value) => {
    // console.log(`handleHotspotChange Updating ${key} for ${placeholder}:`, value);
    
    // Update hotspot definitions based on the selected value for the key (field or table)
    setHotspotDefinitions((prev) => {
      const updatedDefinitions = { ...prev };
      updatedDefinitions[placeholder] = {
        ...(prev[placeholder] || {}),
        [key]: value,
      };
    
      if (key === "table") {
        const selectedTableFields = dashboardStructure[value];
        const hasTickerField = selectedTableFields?.some((f) => f.name.toLowerCase() === "ticker");
        const endsWithData = value.endsWith("_Data");
    
        if (hasTickerField || endsWithData) {
          updatedDefinitions[placeholder] = {
            ...updatedDefinitions[placeholder],
            whereField: "ticker",
            whereValue: "ticker",
          };
        } else if (value === "Category_Userstatus") {
          updatedDefinitions[placeholder] = {
            ...updatedDefinitions[placeholder],
            whereField: "depending on userid/category",
            whereValue: "depending on userid/category",
          };
        } else {
          updatedDefinitions[placeholder] = {
            ...updatedDefinitions[placeholder],
            whereField: null,
            whereValue: null,
          };
        }
      }
      
      console.log("Updated hotspot definitions after modification:", updatedDefinitions);
      return updatedDefinitions;
    });
    
  };
  
  

  // Handle fetching WHERE condition values based on selected field and table
  const handleWhereFieldChange = async (placeholder, field) => {
    const tableSelected = hotspotDefinitions?.[placeholder]?.table; // Get selected table for the placeholder
    await fetchWhereFieldValues(placeholder, field, tableSelected, setWhereFieldValues); // Use service to fetch values
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        setIsSaving(true);
        message.success("Text successfully crafted and saved.");
        onSave(values.craftedText, hotspotDefinitions); // Pass crafted text and hotspot definitions
        onClose(); // Close the modal after saving
      })
      .catch((error) => {
        message.error("Please fill in the required fields.");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleTestText = async () => {
    const craftedText = form.getFieldValue("craftedText");
    try {
      const result = await testTextHotspot({ text: craftedText }); // Adjust payload as necessary
      console.log("result in TextCrafter handleTesttext ", result)
      setTestResult(`Test result: {${result.result}}`); // Display only the result message

      // setTestResult(`Test result: ${JSON.stringify(result)}`); // Display the result
    } catch (error) {
      setTestResult('Error testing hotspot.'); // Handle error
    }
  };

 

  return (
    <Modal
      title="Craft Text"
      visible={true} // Modal should always be visible if it's rendered
      onCancel={onClose} // Handle closing the modal
      footer={null} // Custom footer
    >
      <Form form={form} layout="vertical" initialValues={{ craftedText: initialText }}>
        <Form.Item
          label="Crafted Text"
          name="craftedText"
          rules={[{ required: true, message: "Please input crafted text" }]}
        >
          <Input.TextArea rows={4} placeholder="Enter your crafted text here" />
        </Form.Item>

        {testResult && ( // Render the test result if available
          <div style={{ marginTop: 16 }}>
            <strong>{testResult}</strong>
          </div>
        )}

        <div style={{ textAlign: "right" }}>
          <Button onClick={handleValidate} style={{ marginRight: 8 }}>
            Define HotSpots
          </Button>
          <Button type="default" onClick={handleTestText} style={{ marginLeft: 8 }}>
            Test
          </Button>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave} loading={isSaving}>
            Confirm
          </Button>
        </div>



        {/* Display hotspots found */}
        {placeholders.length > 0 && (
          <div>
            <h4>Define HotSpots:</h4>
            {/* {console.log('handleTestHotspot before generateHotspotFormItems:', handleTestHotspot)}  Log the value of handleTestHotspot */}

            {generateHotspotFormItems(
                  placeholders,
                  hotspotDefinitions,
                  dashboardStructure,
                  handleHotspotChange,
                  whereFieldValues,         // Pass whereFieldValues
                  setWhereFieldValues,      // Correctly pass setWhereFieldValues
                  handleReplacePlaceholder, 
                  testResults,              // Pass testResults state
                  setTestResults,           // Pass the state setter for testResults
                  sentData,
                  handleTestHotspot         // Pass the handleTestHotspot function
                )}

          </div>
        )}
      </Form>
    </Modal>
  );
};

export default TextCrafter;
