//Benchmark_YTD.jsx
import React from 'react';
import { Collapse, Table } from 'antd';
import { useSelector } from 'react-redux';

const { Panel } = Collapse;

const Benchmark_YTD = () => {
  const benchmarkPerformance = useSelector(state => state.portfolio?.data?.benchmark_performance);
  const benchmarkData = useSelector(state => state.portfolio?.parametersData?.b_dataset_json);

  if (!benchmarkData || !benchmarkPerformance) {
    return null;
  }

  const combinedData = benchmarkData.map(bData => {
    const performance = benchmarkPerformance.find(bp => bp[1] === bData.ticker);
    return {
      benchmark_name: bData.benchmark,
      YTD: performance ? performance[3] : 'N/A'
    };
  });

//   console.log('benchmarkData:', benchmarkData);
// console.log('benchmarkPerformance:', benchmarkPerformance);


  const columns = [
    { title: 'Benchmark', dataIndex: 'benchmark_name', key: 'benchmark_name', render: text => <span style={{ fontSize: '12px' }}>{text}</span> },
    { title: 'YTD Performance', dataIndex: 'YTD', key: 'YTD', render: text => <span style={{ fontSize: '12px' }}>{text}%</span> },
  ];

  return (
    <Collapse defaultActiveKey={[]} style={{ fontSize: '12px' }}>
      <Panel header={<span style={{ fontSize: '12px' }}>Benchmark YTD Performance</span>} key="1">
        <Table
          dataSource={combinedData}
          columns={columns}
          pagination={false}
          rowKey="benchmark_name"
          style={{ fontSize: '12px' }}
          size="small"
        />
      </Panel>
    </Collapse>
  );
};

export default Benchmark_YTD;

