// getDataParameters.js
//

import api from './api';

export const fetchDataParameters = async () => {
  try {
    const response = await api.post("/Data_Parameters_load");
    // console.log("Dataparameters fetched")
    // console.log("DP_dataset_json:", response.data.DP_dataset_json);
    // console.log("GOTTEN??? DD_dataset_json:", response.data.DD_dataset_json);
    return { 

      // ETF_symbols_names_json: response.data.ETF_symbols_names_json,

      DP_dataset_json: response.data.DP_dataset_json, 
      b_dataset_json: response.data.b_dataset_json,
      // DD_dataset_json: response.data.DD_dataset_json,
      VDP_dataset_json: response.data.VDP_dataset_json,
      SV_dataset_json: response.data.SV_dataset_json,
      esg_rating_agencies_dataset_json: response.data.esg_rating_agencies_dataset_json
      
      

    };
    
  } catch (error) {
    console.error("Error fetching data parameters:", error);
    return null;
  }
};
