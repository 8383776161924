//PF_Alerts

import React, { useState } from 'react';
import { Table, Tooltip, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import api from '../functions/api';

// Redux action to update the 'seen' status
const updateSeenStatus = (id, seen) => {
  return {
    type: 'UPDATE_SEEN_STATUS',
    payload: { id, seen },
  };
};

const PF_Alerts = () => {
  const dispatch = useDispatch();
  
  const harvestAlerts = useSelector(state => state?.portfolio?.data?.pf_alert);

  // console.log("alerts in PF_Alerts", harvestAlerts)

  // Sort by seen status first, then by ticker in alphabetical order
  const sortedHarvestAlerts = [...harvestAlerts].sort((a, b) => {
    if (a.seen !== b.seen) return a.seen - b.seen; // sort unseen (0) first
    return a.ticker.localeCompare(b.ticker);
  });

  const formatAlertText = (text) => {
    if (!text) return '';
    const becauseIndex = text.indexOf('because:');
    if (becauseIndex === -1) return text;

    const beforeBecause = text.substring(0, becauseIndex + 8); // 'because:'.length === 8
    const afterBecause = text.substring(becauseIndex + 8).replace(/ - /g, '\n- ');
    
    return `${beforeBecause}${afterBecause}`;
  };

  const handleCheckboxChange = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await api.post('/pf_alert_check_update', { id, seen: newStatus });
      dispatch(updateSeenStatus(id, newStatus));
    } catch (error) {
      console.error('Failed to update seen status', error);
    }
  };

  const userId = getUserId();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);

  const handleRowClick = (ticker) => {
    setSelectedTicker(ticker);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => handleRowClick(text)}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record) => (
        <Tooltip title={text}>
          <span
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => handleRowClick(record.ticker)}
          >
            {text.length > 20 ? `${text.substring(0, 20)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      key: 'Category',
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
    },
    {
      title: 'Alert Subtype',
      dataIndex: 'alert_subtype',
      key: 'alert_subtype',
    },
    {
      title: 'Checked?',
      dataIndex: 'seen',
      key: 'seen',
      render: (text, record) => (
        <Checkbox 
          checked={record.seen === 1} 
          onChange={() => handleCheckboxChange(record.ID, record.seen)}
        />
      ),
    },
    {
      title: 'Alert Text',
      dataIndex: 'pf_alert_text',
      key: 'pf_alert_text',
      render: (text) => (
        <Tooltip title={text}>
          <span style={{ whiteSpace: 'pre-wrap' }}>{formatAlertText(text)}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={sortedHarvestAlerts}
        columns={columns}
        rowKey="ID"
        pagination={{ pageSize: 5 }}
      />
      {isModalVisible && (
        <ThreeTablesModal 
          tickerValue={selectedTicker} 
          open={isModalVisible} 
          onClose={() => setIsModalVisible(false)} 
          userId={userId} 
        />
      )}
    </>
  );
};

export default PF_Alerts;

