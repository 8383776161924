/// WidgetPersonal.jsx
import React, { useState } from 'react';
import getUserId from '../functions/getUserId';
import api from '../functions/api';
import { message, Modal, Table } from 'antd';
import '../css/WidgetAreaController.css';
import ThreeTablesModal from "./SearchResultModal";
import SimpleExternalLinkNewWindow from '../functions/LinkFlex';

const WidgetPersonal = ({ subtype, title }) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);
  const [isThreeTablesModalVisible, setIsThreeTablesModalVisible] = useState(false);

  // New function to load data when the modal is triggered
  const loadData = () => {
    const userid = getUserId();
    setLoading(true); // Start loading indicator
    api.get('/widget_personal', {
      params: {
        subtype: subtype,
        userid: userid,
      }
    })
    .then((response) => {
      const standardizedData = response.data.data.map(item => {
        const newItem = {};
        Object.keys(item).forEach(key => {
          newItem[key.toLowerCase()] = item[key];
        });
        return newItem;
      });
      console.log("DATA BEING LOADED FOR THIS WIDGET")
      setData(standardizedData);
      setHeaders(response.data.headers_sequence.filter(header => header.toLowerCase() !== 'url'));
      setLoading(false); // Stop loading indicator
    })
    .catch((error) => {
      message.error('Failed to fetch personal widget data');
      setLoading(false);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
    loadData(); // Load data only when the modal is about to be shown
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onRowClick = (record) => {
    const urlColumnKey = Object.keys(record).find(key => key.toLowerCase().includes('url'));
    if (urlColumnKey && record[urlColumnKey]) {
      window.open(record[urlColumnKey], '_blank');
    }
  };

  const generateAndSortColumns = (headers) => {
    const onTickerClick = (ticker) => {
      setSelectedTicker(ticker);
      setIsThreeTablesModalVisible(true);
    };

    const isUrlColumn = (header) => header.toLowerCase() === 'url';
    const dataIndexForHeader = (header) => header.toLowerCase();

    const renderColumnContent = (header, text, record) => {
      const dataIndex = dataIndexForHeader(header);
      if (isUrlColumn(header)) {
        return <SimpleExternalLinkNewWindow clickedValue={text} url={record[dataIndex]} />;
      } else if (dataIndex === 'ticker') {
        return <a onClick={() => onTickerClick(record[dataIndex])}>{text}</a>;
      } else {
        return text;
      }
    };

    return headers.map(header => ({
      title: header,
      dataIndex: dataIndexForHeader(header),
      key: header,
      render: (text, record) => renderColumnContent(header, text, record),
    }));
  };

  const columns = generateAndSortColumns(headers);

  return (
    <div>
      <div className="grid-spot" onClick={showModal} style={{ cursor: 'pointer' }}>
        <h3 className="widget-title">{title}</h3>
      </div>

      <Modal
        title={title}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Table
          dataSource={data}
          columns={columns}
          pagination={{ pageSize: 10 }}
          loading={loading}
          rowKey="ID"
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
        />
      </Modal>

      {isThreeTablesModalVisible && (
        <ThreeTablesModal
          userId={getUserId()}
          onClose={() => setIsThreeTablesModalVisible(false)}
          open={isThreeTablesModalVisible}
          tickerValue={selectedTicker}
        />
      )}
    </div>
  );
};

export default WidgetPersonal;

// // WidgetPersonal.jsx
// import React, { useState, useEffect } from 'react';
// import getUserId from '../functions/getUserId';
// import api from '../functions/api';
// import { message, Modal, Table } from 'antd';
// import '../css/WidgetAreaController.css';
// import ThreeTablesModal from "./SearchResultModal";
// import SimpleExternalLinkNewWindow from '../functions/LinkFlex';

// const WidgetPersonal = ({ subtype, title }) => {
//   const [data, setData] = useState([]);
//   const [headers, setHeaders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [selectedTicker, setSelectedTicker] = useState(null); // New state for selected ticker
//   const [isThreeTablesModalVisible, setIsThreeTablesModalVisible] = useState(false);
  
//   useEffect(() => {
//     const userid = getUserId();
//     api.get('/widget_personal', {
//       params: {
//         subtype: subtype,
//         userid: userid,
//       }
//     })
//     .then((response) => {

//       console.log("subtype", subtype,"Raw API Response: ", response);
//       // Standardize data keys to lowercase
//       const standardizedData = response.data.data.map(item => {
//         const newItem = {};
//         Object.keys(item).forEach(key => {
//           newItem[key.toLowerCase()] = item[key];
//         });
//         return newItem;
//       });
  
//       setData(standardizedData);
//       setHeaders(response.data.headers_sequence.filter(header => header.toLowerCase() !== 'url')); 
//       setLoading(false);
//     })
//     .catch((error) => {
//       message.error('Failed to fetch personal widget data');
//       setLoading(false);
//     });
//   }, [subtype]);
  
// // Function to handle row clicks
// const onRowClick = (record) => {
//   const urlColumnKey = Object.keys(record).find(key => key.toLowerCase().includes('url'));
//   if (urlColumnKey && record[urlColumnKey]) {
//     // Use window.open to navigate to the URL
//     window.open(record[urlColumnKey], '_blank');
//   }
// };


//   const showModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   // Modified to include ticker column click functionality
//   const generateAndSortColumns = (headers) => {

//     // console.log("Headers for Column Generation: ", headers);

//     const onTickerClick = (ticker) => {
//       // console.log("tickerrr", ticker);
//       setSelectedTicker(ticker);
//       setIsThreeTablesModalVisible(true);
//     };
  
//     // Function to determine if the header corresponds to a URL
//     const isUrlColumn = (header) => header.toLowerCase() === 'url';
  

//     const dataIndexForHeader = (header) => {
//       return header.toLowerCase(); // Always lowercase
//     };
    

  
//     const renderColumnContent = (header, text, record) => {
//       // console.log(`Rendering content for ${header}: text=${text}, record=`, record);
//       const dataIndex = dataIndexForHeader(header);
//       if (isUrlColumn(header)) {
//         return <SimpleExternalLinkNewWindow clickedValue={text} url={record[dataIndex]} />;
//       } else if (dataIndex === 'ticker') {
//         return <a onClick={() => onTickerClick(record[dataIndex])}>{text}</a>;
//       } else {
//         return text;
//       }
//     };
    
  
//     if (Array.isArray(headers)) {
//       return headers.map(header => {
//         const dataIndex = dataIndexForHeader(header);
//         // console.log(`Mapping header '${header}' to dataIndex '${dataIndex}'`); // Diagnostic log
//         return {
//           title: header,
//           dataIndex: dataIndex,
//           key: header,
//           render: (text, record) => renderColumnContent(header, text, record),
//         };
//       });
     
//     } else if (typeof headers === 'object') {
//       return Object.entries(headers)
//         .sort((a, b) => a[1] - b[1])
//         .map(([key,]) => ({
//           title: key.charAt(0).toUpperCase() + key.slice(1),
//           dataIndex: dataIndexForHeader(key),
//           key: key,
//           render: (text, record) => renderColumnContent(key, text, record),
//         }));
//     } else {
//       return [];
//     }
//   };
  

//   const columns = generateAndSortColumns(headers);

//   return (
//     <div>
//       <div className="grid-spot" onClick={showModal} style={{ cursor: 'pointer' }}>
//         <h3 className="widget-title">{title}</h3>
//       </div>

//       <Modal
//         title={title}
//         visible={isModalVisible}
//         onCancel={handleCancel}
//         footer={null}
//         width={1200}
//       >
//         <Table
//           dataSource={data}
//           columns={columns}
//           pagination={{ pageSize: 10 }}
//           loading={loading}
//           rowKey="ID"
//           onRow={(record) => ({
//             onClick: () => onRowClick(record), // This adds the click functionality to the entire row
//           })}
//         />
//       </Modal>

//       {/* Conditionally render ThreeTablesModal based on state */}
//       {isThreeTablesModalVisible && (
//         <ThreeTablesModal
//         userId={getUserId()}
//         // isVisible={isThreeTablesModalVisible}
//         onClose={() => setIsThreeTablesModalVisible(false)}
//         open={isThreeTablesModalVisible}
//         tickerValue={selectedTicker}
//       />
      
//       )}
//     </div>
//   );
// };

// export default WidgetPersonal;
