/// Widget_area_controller.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../css/WidgetAreaController.css';
import WidgetController from './Widget_controller';
import WidgetContentMgr from './WidgetContentMgr'; 
import { EllipsisOutlined } from '@ant-design/icons';

const WidgetAreaController = () => {
  const userId = useSelector(state => state.auth.userId);
  const widgetPreferences = useSelector(state => state.widget.widgetPreferences || []);
  const [activeWidget, setActiveWidget] = useState(null);
  const [isWidgetControllerVisible, setIsWidgetControllerVisible] = useState(false);


  // If the filtered preferences are empty, fallback to a default user ID (e.g., 36)
  let userWidgetPreferences = widgetPreferences.filter(preference => preference.userid === userId);


  const gridHotspots = new Array(12).fill(null).map((_, index) => {
    const preference = userWidgetPreferences.find(pref => pref.widget_number === index + 1);
    return preference ? (preference.widgetcontentid !== 'clear' ? preference.widgetcontentid : null) : null;
  });

  const handleWidgetSpotClick = (index) => {
    setActiveWidget(index + 1);
    setIsWidgetControllerVisible(true);
  };

  const handleManageContentClick = (index, event) => {
    setActiveWidget(index + 1);
    setIsWidgetControllerVisible(true);
    event.stopPropagation(); // Prevent event bubbling to the parent div
  };
  

  const handleContentSelected = (selectedContentId) => {
    // Logic to handle the selected content
    setIsWidgetControllerVisible(false); // Close the WidgetController modal
    // setContentModalVisible(false); // Close the content modal if it's open
    // Further logic to update the Redux state or backend to reflect the new content selection
  };

  return (
    <div className="widget-grid">
      {gridHotspots.map((widgetContentId, index) => (
        <div
          key={index}
          className={`grid-spot ${widgetContentId ? 'filled' : 'empty'}`}
          onClick={() => handleWidgetSpotClick(index)}
        >
          {widgetContentId && (
            <EllipsisOutlined 
              onClick={(e) => handleManageContentClick(index, e)} 
              style={{ position: 'absolute', top: '1px', right: '5px', zIndex: 3 }} 
            />
          )}
          {widgetContentId ? (
            <div onClick={(e) => e.stopPropagation()}>
              <WidgetContentMgr widgetcontentid={widgetContentId} />
            </div>
          ) : (
            <div className="widget-placeholder">Press to choose content for this widget</div>
        
          )}
        </div>
      ))}
      {isWidgetControllerVisible && (
        <WidgetController
          userId={userId}
          widgetNumber={activeWidget}
          onContentSelected={handleContentSelected}
          onClose={() => setIsWidgetControllerVisible(false)}
          existingContentId={gridHotspots[activeWidget - 1]}
        />
      )}
    </div>
  );

};

export default WidgetAreaController;

