import React, { useEffect, useState } from 'react';
import { Modal, Spin, message } from 'antd';
import SMAGraph from './SMAGraph';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import api from '../functions/api';

const WidgetGraph = ({ title, widgetcontentid }) => {
  const [graphData, setGraphData] = useState(null);
  const [currency, setCurrency] = useState("USD");
  const [ticker, setTicker] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [isGraphModalVisible, setIsGraphModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);

  const toggleGraphModal = () => {
    setIsGraphModalVisible(!isGraphModalVisible);
  };

  const openThreeTablesModal = () => {
    setThreeTablesModalVisible(true);
  };

  const fetchTransactionData = async () => {
    try {
      const response = await api.post("/fetch_Transactions", {
        ticker: ticker,
        userid: getUserId(),
      });
      const formattedData = processTransactionData(response.data);
      setTransactionData(formattedData);
      console.log("Fetched Transaction Data:", response.data);
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    }
  };

  const processTransactionData = (transactions) => {
    return transactions.map(transaction => ({
      date: new Date(transaction.date).toISOString().split('T')[0],
      price: transaction.price,
      type: transaction.buysell,
      quantity: transaction.quantity
    }));
  };

  const transformGraphData = (data) => {
    const { dates, prices, sma_short: smaShort, sma_long: smaLong } = data;
    return dates.map((date, index) => ({
      date,
      price: prices[index],
      smaShort: smaShort[index],
      smaLong: smaLong[index],
    }));
  };

  useEffect(() => {
    if (isGraphModalVisible && widgetcontentid) {
      setLoading(true);
      api.get(`/widget_content_delivery?widgetcontentid=${widgetcontentid}`)
        .then(response => {
          const { graph_data, currency, ticker } = response.data;
          const transformedData = transformGraphData(graph_data); // Transform data here
          setGraphData(transformedData);
          setCurrency(currency || "USD");
          setTicker(ticker);
          setLoading(false);
        })
        .catch(error => {
          message.error('Failed to fetch graph data.');
          setLoading(false);
          console.error(error);
        });

      if (ticker) fetchTransactionData();
    }
  }, [isGraphModalVisible, widgetcontentid, ticker]);

  return (
    <>
      <div onClick={toggleGraphModal} style={{ cursor: 'pointer' }}>
        <h3>{title}</h3>
      </div>

      <Modal
        title={
          <div onClick={openThreeTablesModal} style={{ cursor: 'pointer' }}>
            {`${title} (${currency})`}
          </div>
        }
        visible={isGraphModalVisible}
        onCancel={() => setIsGraphModalVisible(false)}
        footer={null}
        width={700}
        destroyOnClose
      >
        {loading ? (
          <Spin tip="Loading graph data..." />
        ) : (
          graphData ? (
            <SMAGraph
              data={graphData}
              currency={currency}
              ticker={ticker}
              transactions={transactionData}
            />
          ) : (
            <div>No data available for this graph.</div>
          )
        )}
      </Modal>

      {threeTablesModalVisible && (
        <ThreeTablesModal
          userId={getUserId()}
          tickerValue={ticker}
          open={threeTablesModalVisible}
          onClose={() => setThreeTablesModalVisible(false)}
        />
      )}
    </>
  );
};

export default WidgetGraph;



// import React, { useState, useEffect } from 'react';
// import { Modal } from 'antd';
// import SMAGraph from './SMAGraph';
// import MiniGraph from './MiniGraph'; // Import MiniGraph
// import '../css/MiniGraph.css';
// import ThreeTablesModal from "./SearchResultModal";
// import getUserId from '../functions/getUserId';
// import api from '../functions/api';

// const WidgetGraph = ({ title, data, currency, transactions, ticker }) => {
//   const [isGraphModalVisible, setIsGraphModalVisible] = useState(false);
//   const [threeTablesModalVisible, setThreeTablesModalVisible] = useState(false);
//   const [transactionData, setTransactionData] = useState([]);

//   const fetchTransactionData = async () => {
//     try {
//       const response = await api.post("/fetch_Transactions", {
//         ticker: ticker,
//         userid: getUserId(),
//       });
//       console.log("Fetched Transaction Data:", response.data);
  
//       const formattedTransactionData = processTransactionData(response.data);
//       setTransactionData(formattedTransactionData);
//     } catch (error) {
//       console.error("Error fetching transaction data:", error);
//     }
//   };
  
//   const processTransactionData = (transactionData) => {
//     return transactionData.map(transaction => ({
//       date: new Date(transaction.date).toISOString().split('T')[0], // Convert to 'YYYY-MM-DD' format
//       price: transaction.price,
//       type: transaction.buysell,
//       quantity: transaction.quantity
//     }));
//   };

//   // This will open the ThreeTablesModal
//   const openThreeTablesModal = () => {
//     setThreeTablesModalVisible(true);
//   };

//   const toggleGraphModal = async () => {
//     // Only fetch data if the modal is currently not visible and is about to be opened.
//     if (!isGraphModalVisible) {
//       await fetchTransactionData();
//     }
//     setIsGraphModalVisible(!isGraphModalVisible);
//   };

//   // Call this function when the modal is about to open
// useEffect(() => {
//   if (isGraphModalVisible) {
//     fetchTransactionData();
//   }
// }, [isGraphModalVisible, ticker]);

// // Safety check before accessing 'data'
// if (!data || !data.dates || !data.prices) {
//   return <div>Loading...</div>; // Or any other fallback UI
// }



//   // Extract the necessary parts from the data for the mini graph
//   const miniGraphData = data.dates?.map((date, index) => ({
//     date: date, // Assuming date is a string in the 'yyyy-mm-dd' format
//     price: data.prices[index],
//   }));


//   // Prepare the full data for the SMAGraph for use in the modal
//   const formattedData = data.dates?.map((date, index) => ({
//     date: date,
//     price: data.prices[index],
//     smaShort: data.sma_short[index],
//     smaLong: data.sma_long[index],
//   }));

// //   console.log("minigraphdata", miniGraphData)
//   // Assuming the 'data' prop is an object with 'dates' and 'prices' arrays.
//   const latestPrice = data.prices && data.prices.length > 0
//   ? Number(data.prices[data.prices.length - 1]).toFixed(2)
//   : null;


//   return (
//     <>
//       <div className="grid-spot graph-container" onClick={toggleGraphModal} style={{ cursor: 'pointer', paddingBottom: '10px' }}>
//       {/* <div onClick={toggleGraphModal} style={{ cursor: 'pointer', paddingBottom: '10px' }}> */}
//       <h3>{`${title}: ${latestPrice ? `${currency} ${latestPrice}` : 'Loading...'}`}</h3>

//         {/* Render the MiniGraph below the title */}
//         <MiniGraph data={miniGraphData} onClick={toggleGraphModal}/>
//       </div>

//       <Modal
        
//         title={
//           <div onClick={openThreeTablesModal} style={{ cursor: 'pointer' }}>
//             {`${title}: ${latestPrice ? `${currency} ${latestPrice}` : 'Loading...'}`}
//           </div>
//         }

//         visible={isGraphModalVisible}
//         onCancel={() => setIsGraphModalVisible(false)}
//         footer={null}
//         width={700}
//       >
//         {/* Use the full data with SMA lines for the detailed graph in the modal */}
//         <SMAGraph 
//           data={formattedData} 
//           currency={currency} 
//           ticker={ticker}
//           transactions={transactionData}
//         />
//       </Modal>
//       {threeTablesModalVisible && (
//         <ThreeTablesModal
//           userId={getUserId()}
//           tickerValue={ticker}
//           open={threeTablesModalVisible}
//           onClose={() => setThreeTablesModalVisible(false)}
//         />
//       )}
//     </>
//   );
// };

// export default WidgetGraph;
