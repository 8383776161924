// //WidgetContentMgr
import React, { useState, useEffect } from 'react';
import api from '../functions/api';
import { message } from 'antd';
import WidgetTable from '../components/WidgetTable';
import WidgetGraph from '../components/WidgetGraph';
import WidgetPersonal from '../components/WidgetPersonal';

const WidgetContentMgr = ({ widgetcontentid }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (widgetcontentid) {
      api.get(`/widget_title_delivery?widgetcontentid=${widgetcontentid}`)
        .then(response => {
          setContent(response.data); // Store title and type information from the backend
          // console.log("content in WidgetCOntentmgr ", content)
        })
        .catch(error => {
          message.error('Failed to fetch widget title.');
          console.error(error);
        });
    }
  }, [widgetcontentid]);

  const renderContentBasedOnType = () => {
    if (!content) {
      return <div>Loading title...</div>;
    }

    switch (content.type) {
      case 'table':
        return <WidgetTable title={content.title} widgetcontentid={widgetcontentid} />;
      case 'graph':
        return <WidgetGraph title={content.title} widgetcontentid={widgetcontentid} />;
      case 'widget_personal':
        return content.subtype ? (
          <WidgetPersonal subtype={content.subtype} title={content.title} />
        ) : (
          <div>Unsupported or incomplete widget_personal content.</div>
        );
      default:
        return <div>Unsupported content type</div>;
    }
  };

  return renderContentBasedOnType();
};

export default WidgetContentMgr;



// import React, { useState, useEffect } from 'react';
// import api from '../functions/api';
// import { message, Modal } from 'antd';
// import WidgetTable from '../components/WidgetTable';
// import WidgetGraph from '../components/WidgetGraph';
// import WidgetPersonal from '../components/WidgetPersonal';

// const WidgetContentMgr = ({ widgetcontentid, visible, onClose }) => {
//   const [content, setContent] = useState(null);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     if (widgetcontentid) {
//       setLoading(true);
      
//       api.get(`/widget_content_delivery?widgetcontentid=${widgetcontentid}`)
//         .then(response => {
//           setContent(response.data); // Store the entire response from the backend
//           // console.log("for widgetcontentid", widgetcontentid)
//           // console.log("Widget content received ", response.data); // Log the received data
//           setLoading(false);
//         })
//         .catch(error => {
//           message.error('Failed to fetch widget content.');
//           console.error(error); // Log the error
//           console.log("error with widgetcontentid", widgetcontentid)
//           setLoading(false);
//         });
//     }
//   }, [widgetcontentid]);
  

//   if (loading) {
//     return <div>Loading content...</div>;
//   }

//   const renderContentBasedOnType = () => {
//     // Check if content exists and handle different types
//     if (!content) {
//       return <div>No content available</div>;
//     }
  
//     // console.log("CONTENT", content.type, content)

//     switch (content.type) {
//       case 'table':
//         return (
//           <WidgetTable 
//             title={content.title}
//             data={content.data}
//             headersSequence={content.headers_sequence}
//             headersSequence2={content.headers_sequence2}
//             datafields_thresholds={content.datafields_thresholds}
//           />
//         );
  
//       case 'graph':
//         return (
//           <WidgetGraph 
//             title={content.title}
//             data={content.graph_data}
//             currency={content.currency}
//             ticker={content.ticker}
//             transactions={content.transactions} // Assuming transactions data is part of the response
//           />
//         );
  
//         case 'widget_personal':
//           // console.log("checking data", content.message, content.type, content.subtype);

//           if (content.message && content.type === 'widget_personal' && content.subtype) {
//               // console.log(content.message); // Log the message for debugging purposes.
//               // Render WidgetPersonal with the provided subtype.
//               return <WidgetPersonal subtype={content.subtype} title={content.title}/>;
//           } else {
//               // Handle cases where content or subtype might not be fully specified.
//               return <div>Unsupported or incomplete widget_personal content.</div>;
//           }
//         default:
//         return <div>Unsupported content type</div>;
//     }
//   };
//   return renderContentBasedOnType();
// };
// export default WidgetContentMgr;
